import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import CustomNavbar from "../Components/Layouts/CustomNavbar";
import Feedback from "../Components/Layouts/Feedback";
import Footer from "../Components/Layouts/Footer";
import Navsmall from "../Components/Layouts/Navsmall";
import { APP_URL } from "../Components/processENV";

const isAuthUser = () =>{
    if(localStorage.getItem('status') === 'completed'){
        return true
    }
   else return false
}

const PrivateRoute = props => {
  const { path,  component : Component } = props;
  if(isAuthUser()) { return (
    <div className="statusColor pb-0" style={{height:'100vh'}}>
  <Navsmall />
  <CustomNavbar/>
   <Component />
   <Feedback/>

   {/* <Footer /> */}

   </div>
 )}
  else if(!isAuthUser()){
    window.location.replace('https://app.careme.health')
  };

  return <Route  {...props} />;
};


export default PrivateRoute;