import React from "react";
import { connect } from "react-redux";
import { ArrowLeftCircle, Check, Flag } from "react-feather";
import Store from "../../../../Store";
import { ScheduleAction } from "../../../../Actions/ScheduleAction";
import Loader from "../../Loader";
import { REACT_APP_API_URL } from "../../../processENV";
import { Container, Row, Col } from "reactstrap";
import { dialdata } from "../../../../Helpers/dialdata";

class CoupleQues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      first_name: "",
      mobileNumber: "",
      dial_code: "+91",
      last_name: "",
      ccError: false,
      mobileError: false,
      error: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  selectproblem = (value) => {
    Store.dispatch(
      ScheduleAction({
        problem: value,
        mode: this.props.scheduleData.data.mode,
        scheduleStep: 2,
        scheduleCurrentStep: 2,
        
      })
    );
  };
  submitValues = () => {
    const { first_name, last_name, mobileNumber, dial_code } = this.state;
    console.log(this.state);
    if (
      first_name.trim().length >= 2 &&
      last_name.trim().length >= 2 &&
      mobileNumber.length >= 2
    ) {
      var data = {
        first_name: first_name,
        last_name: last_name,
        dial_code: dial_code,
        phone_number: mobileNumber,
        patient_n_key: "",
      };
      fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          if (res.status === "success") {
            let selectedProblem = { app_prod_n_key: "APP_PROD-5" };
            Store.dispatch(
              ScheduleAction({
                scheduleStep: 1,
                scheduleCurrentStep: 1,
                pt_key: res.data.patient_n_key,
                problem: selectedProblem,
                app_payment_n_key:this.props.scheduleData?.data?.app_payment_n_key,
                mode: "Video",
              })
            );
          } else {
            console.log("err");
          }
        });
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  };
  onInputChange = (e) => {
    let item = e.target.name;
    let dc = this.state.dial_code ? this.state.dial_code : null;
    this.setState({ mobileNumber: e.target.value });
    if (dc == "+91") {
      if (e.target.value.length === 10) {
        this.setState({ mobileError: false, ccError: false });
      }
    }
  };
  goBack = () => {
    window.location.href = "/home";
  };

  render() {
    const {
      problems,
      time,
      loading,
      values,
      ccError,
      mobileError,
      selectedTime,
      spots,
    } = this.state;
    return (
      <>
        {loading && <Loader />}
        <div style={{height:'100vh'}} className="choose-language-outer pt-4 secondColor">
          <button
            onClick={this.goBack}
            className="btn btn-sm mt-5 mt-md-1 border-none "
          >
            <ArrowLeftCircle color="#215352" size={25} />
          </button>
          <div className="d-flex">
            <Col md={6}
              className="mx-auto text-center choose-language-inner px-0">
              <h3 className="header_font_">Partner Contact Details</h3>
              <Row className="mx-md-auto mt-5 mx-1">
                <Col md={12} className="mx-auto px-0">
                  <div className="d-inline input_Icon">
                    <input
                      type="text"
                      autoFocus
                      className="form-control col-md-9 col-10 d-inline "
                      placeholder="First Name"
                      defaultValue={this.state.first_name}
                      onChange={(e) =>
                        this.setState({ first_name: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mx-md-auto mt-md-5 mt-2 mx-1">
                <Col md={12} className="mx-auto px-0">
                  <div className="d-inline input_Icon">
                    <input
                      type="text"
                      className="form-control col-md-9 col-10 d-inline "
                      placeholder="Last Name"
                      defaultValue={this.state.last_name}
                      onChange={(e) =>
                        this.setState({ last_name: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mx-md-auto mt-md-5 mt-2 mx-1">
                <Col md={12} className="mx-auto px-0">
                  <select
                    name="countryCode"
                    className={`form-control col-md-2 col-3 pl-1 d-inline ${
                      ccError ? "is-invalid" : ""
                    }`}
                    value={this.state.dial_code}
                    placeholder=""
                    onChange={(e) =>
                      this.setState({ dial_code: e.target.value })
                    }
                  >
                    {dialdata?.map((item, i) => {
                        return <option key={item.name} value={item.code}>{item.code}</option>;
                      })}
                  </select>
                  <div className="d-inline input_Icon">
                    <input
                      type="number"
                      name="mobileNumber"
                      className={`form-control col-7 d-inline ${
                        mobileError ? "is-invalid" : ""
                      }`}
                      defaultValue={this.state.mobileNumber}
                      onChange={this.onInputChange}
                    />
                  </div>
                </Col>
              </Row>
              {ccError && <p className="error">Country code is not valid</p>}
              {mobileError && <p className="error">Enter Valid Number</p>}
              <button
                className="px-5 py-2 mt-5 rounded btn newbtn"
                onClick={() => this.submitValues()}
              >
                Submit
              </button>
              <br />
              {this.state.error && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  All the fields are mandatory
                </span>
              )}
            </Col>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    scheduleData: state.ScheduleData,
  };
};

export default connect(mapStateToProps, null)(CoupleQues);
