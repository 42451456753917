import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { Download, ArrowRight } from "react-feather";
import { REACT_APP_API_URL } from "../../processENV";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
import axios from "axios";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import FirstSessionCallout from "../../NoAppointment";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isOpen: false,
      docname: "",
      file: "",
      doctype: "",
      doctypeenter: "",
      documentList: [],
      modal: false,
      add_modal: false,
      typ_modal: false,
      other_doc: "",
      doc_details: {
        d_name: "",
        attachment: "",
        doc_type: "",
        descrption: "",
      },
    };
  }

  componentDidMount() {
    this.getDocumentList();
  }
  Modalopen = (e) => {
    if (e !== null && e !== "") {
      this.setState({
        modal: true,
        documentview: e,
      });
    }
  };

  toastSuccess = (e) =>
    toast.success(e, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  toastError = (e) =>
    toast.error(e, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  collapsePlan = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  documentInputHandler = (e) => {
    let id = e.target.name;
    this.setState({
      [id]: e.target.value,
    });
  };
  submitDocument = (e) => {
    const { doc_details, other_doc } = this.state;
    let formData = new FormData();
    formData.append("document_attachment", doc_details.attachment);
    formData.append("document_name", doc_details.d_name);
    formData.append("doc_type", other_doc ? other_doc : doc_details.doc_type);
    formData.append("patient_n_key", localStorage.getItem("_patientKey"));
    var self = this;
    this.setState({ loading: true, error: "" });
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    axios({
      method: "post",
      url: `${REACT_APP_API_URL}/appdocument_post/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    }).then(function (res) {
      console.log(res);
      if (res.data.status === "success") {
        self.setState({ add_modal: false });
        self.setState({
          other_doc: "",
          doc_details: {
            d_name: "",
            attachment: "",
            doc_type: "",
            descrption: "",
          },
        });
        toast.success("Document uploaded!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        self.getDocumentList();
      } else {
        self.setState({
          loading: false,
          error: "Some error has occured please try again",
        });
        toast.error("Some error has occured please try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal, feedback: "" });
  };
  getDocumentList = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/patient_app_document/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        this.setState({ documentList: res.data });
      })
      .catch((err) => console.log("alcohol list error", err));
  };
  downld = async (document_attachment, document_name) => {
    saveAs(document_attachment, document_name);
  };
  handleChange = async (key, value) => {
    this.setState({
      doc_details: {
        ...this.state.doc_details,
        [key]: value,
      },
    });
    if (key == "doc_type") this.setState({ typ_modal: false });
  };
  submitCheck = async () => {
    const value = { ...this.state.doc_details };
    console.log(value);
    if (value.d_name.trim().length < 2) {
      toast.error("Document Name should be atleast 2 characters", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!value.attachment) {
      toast.error("File/Doc is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (value.attachment.size / 1024 / 1024 > 10) {
      toast.error("File size should not exceed 10MB", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!value.doc_type) {
      toast.error("Please select a document type", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (value.doc_type == "Others") {
      if (this.state.other_doc.trim().length > 2) {
        this.submitDocument();
      } else {
        toast.error(
          "Document type is required & should be atleast 2 characters",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      this.submitDocument();
    }
  };
  render() {
    const { loading, typ_modal, doc_details, add_modal, documentList } =
      this.state;
    let { t } = this.props;

    return (
      <div className="container" style={{height:'100vh'}}>
        <div className="col-md-8 mx-auto mt-5">
          <ToastContainer />
          <h3
            style={{ textDecorationLine: "underline" }}
            className="header_font_ _text_color text-center mt-md-5 pt-md-3 pt-5"
          >
            My Uploaded Documents
          </h3>

          <Modal size="sm" centered isOpen={typ_modal}>
            <ModalHeader
              toggle={() => this.setState({ typ_modal: false })}
            ></ModalHeader>
            <ModalBody>
              <div className="mod_item_div">
                <h4
                  onClick={() => this.handleChange("doc_type", "Surgeries")}
                  style={{
                    background: "#fff",
                    color: "#215352",
                    marginTop: "8px",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: "16px",
                    border: "1px solid #215352",
                  }}
                >
                  Surgeries
                </h4>
                <h4
                  onClick={() => this.handleChange("doc_type", "Medications")}
                  style={{
                    background: "#fff",
                    color: "#215352",
                    marginTop: "8px",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: "16px",
                    border: "1px solid #215352",
                  }}
                  className="mod_item"
                >
                  Medications
                </h4>
                <h4
                  onClick={() => this.handleChange("doc_type", "Allergies")}
                  style={{
                    background: "#fff",
                    color: "#215352",
                    marginTop: "8px",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: "16px",
                    border: "1px solid #215352",
                  }}
                  className="mod_item"
                >
                  Allergies
                </h4>
                <h4
                  onClick={() => this.handleChange("doc_type", "Immunizations")}
                  style={{
                    background: "#fff",
                    color: "#215352",
                    marginTop: "8px",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: "16px",
                    border: "1px solid #215352",
                  }}
                  className="mod_item"
                >
                  Immunizations
                </h4>
                <h4
                  onClick={() => this.handleChange("doc_type", "Others")}
                  style={{
                    background: "#fff",
                    color: "#215352",
                    marginTop: "8px",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: "16px",
                    border: "1px solid #215352",
                  }}
                  className="mod_item"
                >
                  Others
                </h4>
              </div>
            </ModalBody>
          </Modal>
          {/* {documentList.length !== 0 ? ( */}
          <div className="text-right my-5 pt-5 mx-auto col-md-8">
            <button
              onClick={() => this.setState({ add_modal: !add_modal })}
                 className="btn newbtn"
            >
              Add Documents {add_modal ? "-" : "+"}
            </button>
          </div>
          {/* ) : null} */}
          {add_modal ? (
            <>
              <div className="col-md-7  text-center mx-auto">
              <h5
                  style={{ textDecorationLine: "underline", color: "#215352" }}
                  className="mt-3 header_font_ _text_color"
                >
                  ADD YOUR DOCUMENTS
                </h5>
                <div className="col d-flex flex-column mt-5">
                  <label className="text-left header_font_">
                    Name of the document <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={doc_details.d_name}
                    onChange={(e) =>
                      this.handleChange("d_name", e.target.value)
                    }
                    placeholder="Document name (minimum 2 characters)"
                  />
                </div>
                <div className="col d-flex flex-column mt-3">
                  <label className="text-left header_font_">
                    Upload file (max 10MB){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div class="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={(e) =>
                        this.handleChange("attachment", e.target.files[0])
                      }
                      placeholder="X-ray.jpg"
                    />
                    <label
                      className="custom-file-label text-left"
                      for="customFile"
                    >
                      {" "}
                      choose file
                    </label>
                  </div>
                </div>
                <div className="col d-flex flex-column mt-3">
                  <label className="text-left header_font_">
                    Type of document <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={doc_details.doc_type}
                    onClick={() => this.setState({ typ_modal: true })}
                    placeholder="select document type"
                  />
                </div>
                {doc_details && doc_details.doc_type == "Others" ? (
                  <div className="col d-flex flex-column mt-3">
                    <label className="text-left header_font_">
                      Name of the document{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={this.state.other_doc}
                      onChange={(e) =>
                        this.setState({ other_doc: e.target.value })
                      }
                      placeholder="Document type"
                    />
                  </div>
                ) : null}
                <div className="col d-flex flex-column mt-3">
                  <label className="text-left header_font_">Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    defaultValue={doc_details.descrption}
                    onChange={(e) =>
                      this.handleChange("descrption", e.target.value)
                    }
                    placeholder="Description (optional)"
                  ></textarea>
                </div>
                <div className="mt-4 mb-2">
                  <button
                                       className="btn newbtn"

                    onClick={() => this.submitCheck()}
                  >
                    ADD DOCUMENT
                  </button>
                </div>
              </div>
            </>
          ) : null}
          {documentList.length !== 0 ? (
            <>
              {documentList.map((i, indx) => {
                return (
                  <div
                    style={{ border: "1px solid #215352" }}
                    className="row shadow-sm col-md-6 m-2 mt-4 mx-auto d-flex bd-highlight position-relative rounded"
                  >
                    <div className=" px-2 py-3 flex-fill bd-highlight">
                      <p className="_text_color pt-2">
                        Document: {i.document_name}
                      </p>
                      <p
                        style={{ opacity: 0.7, fontSize: "10px" }}
                        className="mt-n2 _text_color"
                      >
                        Category: {i.doc_type}
                      </p>
                    </div>
                    <div
                      style={{ right: 70 }}
                      className=" p-4 flex-fill bd-highlight position-absolute"
                    >
                      <Download
                        onClick={() =>
                          this.downld(i.document_attachment, i.document_name)
                        }
                        // className="dow_icn"
                        size={25}
                        color="#215352"
                      />
                    </div>
                    <div
                      style={{ right: 20 }}
                      className=" p-4 flex-fill bd-highlight position-absolute"
                    >
                      {" "}
                      <a href={i.document_attachment} target="_blank">
                        <AiFillEye size={25} color="#215352" />{" "}
                      </a>
                    </div>
                  </div>
                );
              })}
            </>
          ) : !add_modal? 
            <FirstSessionCallout />:null
             }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(Documents));
