import React from 'react';
import { Form, Card } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';

const AssessmentStep = ({ assessmentCategory, currentIndex, onSelectAnswer }) => {
  const { assessmentSet } = assessmentCategory;
  const currentQuestion = assessmentSet[currentIndex];

  const animation = useSpring({
    from: { opacity: 0, transform: 'translateX(50px)' },
    to: { opacity: 1, transform: 'translateX(0px)' },
    reset: true,
    config: { duration: 300 },
  });

  if (!currentQuestion) return null;

  return (
    <animated.div
      style={{
        ...animation,
        padding: '1rem',
        marginLeft: '-2rem',
      }}
      className="my-4"
    >
      <Card className="p-4 shadow-none border-0 statusColor">
        <h4 className="mb-4 text-left">{currentQuestion.question}</h4>
        {currentQuestion.answerOption?.map((option) => {
          const isChecked = currentQuestion.answer === option.value;

          return (
            <button
              key={option.value}
              variant={isChecked ? 'primary' : 'outline-dark'}
              className={`mb-3 w-50  text-left ${
                isChecked ? 'secondColor borderColor fontColor' : 'bg-white'
              }`}
              style={{
                padding: '10px 16px',
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: isChecked ? '600' : '400',
              }}
              onClick={() => onSelectAnswer(option.value)}
            >
              {option.label}
            </button>
          );
        })}

      </Card>
    </animated.div>
  );
};

export default AssessmentStep;
