import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { connect } from 'react-redux';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import { Link } from "react-router-dom";

import Slider from "react-slick";
import Reviews from './Reviews';
import FaqMarquee from './FaqMarquee';
import MainSection from './MainSection';
import Footer from '../../Layouts/Footer';
import { APP_URL } from '../../processENV';

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }
 
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };

    componentDidMount(){
        if(localStorage.status!=='completed'){
            window.location.replace(APP_URL)
        }
    }

    render() {
        let { isOpen } = this.state;
        return (
            <div style={{height:'100vh'}} className={"login-main homecontainer mx-auto statusColor"}>
                           <Container className='m-0 p-0 mx-md-auto' >
                               <Row className='p-md-2 p-0 ml-md-5 ml-2 mx-md-auto '>
                                   
                           {/* <Col> */}
                           <MainSection />
                              {/* </Col> */}
                              {/* <Col md={3} className="pl-2">
                              <FaqMarquee />
                              </Col> */}
                              </Row>
       
                               <Reviews />
                              
                           </Container>
                          
                       </div>
                     
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(HomePage);
