import React, { Component } from "react";
import { Clock, MessageSquare } from "react-feather";
import {
  REACT_APP_API_URL,
  RET_URL,
  getCurrencySymbol,
} from "../../processENV";
import { FaHandshake } from "react-icons/fa";
import { IoMdChatbubbles } from "react-icons/io";
import { BsCircleFill, BsCloudSunFill } from "react-icons/bs";

class Chatpay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      selected_product: "",
      product: "",
      p_name: "",
      p_phone: "",
      p_email: "",
      orderId: "",
      p_dial_code: "",
    };
  }

  componentDidMount() {
    this.getChat();
    this.getName();
  }

  payInit = async (id,i,amount) => {
    const { product } = this.state;
    console.log(i,product)
    const data = JSON.stringify({
      patient_n_key: localStorage._patientKey,
      total_session: 1,
      payment_status: "Payment Initiated",
      amount: amount,
      payment_details: id,
      product_amount: i?.product_amount,
      discount: i?.discount,
      discount_amount: i?.discount_amount,
      product: product[0]?.product_title,
      app_prod_n_key: product[0]?.app_prod_n_key,
    });
    console.log(data);
    fetch(REACT_APP_API_URL + "/cashfree_payment/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: data,
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("pay_on", res);
        if (res.status === "success") {
          localStorage._ks = res.data.app_payment_n_key
          console.log("INitated");
        } else {
          console.log("meeting id", res.data);
        }
      });
  };
  getName = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/patientedit_app/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({
            p_name: res.data.first_name,
            p_dial_code: res.data.dial_code,
            p_phone: res.data.phone_number,
            p_email: res.data.email,
          });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };
  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/counselling_plan/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("plan_Res", res.plan);
        if (res.status === "success") {
          this.setState({ item: res.plan, product: res.product });
          console.log(this.state.item[0]);
        } else {
          console.log("meeting id", res.data);
        }
      });
  };
  selectProduct = async (id) => {
    this.setState({ selected_product: id });
  };
  gotoPay = async (i) => {
    const { p_email, p_name, p_phone, selected_product, product, p_dial_code } =
      this.state;
    this.setState({ selected_product: i });
    let data = {
      orderAmount: i.amount.toString(),
      customerName: p_name,
      customerPhone: p_phone,
      customerEmail: p_email,
      returnUrl: RET_URL,
      currency: i.currency,
      dial_code: p_dial_code,
      order_note:'Counsellingchat'
    };
    fetch(`${REACT_APP_API_URL}/create_paymentorder/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res.status === "success") {
          console.log("payment link", res);
          localStorage.setItem("orderId", res.orderId);
          localStorage.setItem("purchase_type", "COACH");
          localStorage.setItem("_cc", JSON.stringify({ _ps: i, _p: product }));
          this.payInit(res.orderId, i, i?.amount);
          const cf = new global.Cashfree(res.payment_session_id);
          cf.redirect();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => console.log("error", err));
  };
  render() {
    const { item, selected_product } = this.state;
    return (
      <div className="container">
      <div
        className="col-md-8 mx-auto my-5 statusColor px-0 px-md-5 shadow-none py-2 overflow-auto"
      >
        <h3
          className="pt-5 header_font_ fontColor text-center font-weight-bold"
        >
          Discover a Better You with Our Expert Coaching{" "}
        </h3>
        <div className="col-md-12 mt-4 text-center">
          <div className="col-md-12 mx-auto text-center">
            <p
              className="text-left fontColor text-md-center text_p_"
              style={{fontSize: "16px" }}
            >
              🤝 Expert, dedicated coach for clients
            </p>
            <p
              className="text-left fontColor text-md-center text_p_ mt-n2"
              style={{fontSize: "16px" }}
            >
              🌱 Personalized habit-building approach{" "}
            </p>
            <p
              className="text-left fontColor text-md-center text_p_ mt-n2"
              style={{fontSize: "16px" }}
            >
              📆 Weekly progress check-ins to track
            </p>
            <p
              className="text-left fontColor text-md-center text_p_ mt-n2"
              style={{fontSize: "16px" }}
            >
              🧘 Mental Health wellness strategies{" "}
            </p>
            <p
              className="text-left fontColor text-md-center text_p_ mt-n2"
              style={{fontSize: "16px" }}
            >
              💪 Accountability drives goal attainment{" "}
            </p>
          </div>
        </div>
        <h3
          className="header_font_ mt-md-3 pt-2 fontColor h4 text-center font-weight-bold"
        >
          Choose the plan that's right for you and start your journey today!{" "}
        </h3>
        <div className="row  mt-md-2 mt-1 px-md-0 px-4">
          <div className="row col-md-10 mx-auto mt-2 p-4 d-flex flex-row justify-content-center">
            <div
              style={{ background: "#e8d6c8" }}
              className="col-md-4 m-3 shadow rounded p-0 position-relative"
            >
              <h5 className=" text-center text_font _text_color pt-3 header_font_">
                💥 {item[0]?.plan}{" "}
              </h5>
              <p className="card-text h2 text-center text_p_font _text_color font-weight-bold">
                {getCurrencySymbol(item[0]?.currency)} {item[0]?.amount}
              </p>
              <p
                style={{ textDecorationLine: "line-through" }}
                className="card-text h4 text-center text_p_font _text_color font-weight-bold"
              >
                {getCurrencySymbol(item[0]?.currency)}{" "}
                {item[0]?.product_amount}
              </p>
              <p className="text-center text_plan_days_ _text_color my-2 pb-5">
                <span>{item[0]?.no_of_session} day</span>
              </p>
              <div
                style={{ bottom: 0, background: "#ffff" }}
                className="text-center position-absolute w-100 m-0 p-0 border rounded mb-2"
              >
                <button
                  onClick={() => this.gotoPay(item[0])}
                  style={{ color: "#215352", fontWeight: "bold" }}
                  className="btn btn-block _text_color"
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div
              style={{ background: "#e0e8d8" }}
              className="col-md-4 m-3 shadow rounded p-0 position-relative"
            >
              <div class="ribbon ribbon-top-left">
                <span>Popular</span>
              </div>
              <h5 className=" text-center text_font _text_color pt-4 pl-md-1 header_font_">
                {" "}
                👣 {item[1]?.plan}
              </h5>
              <p className="card-text h2 text-center text_p_font _text_color font-weight-bold">
                {getCurrencySymbol(item[1]?.currency)} {item[1]?.amount}
              </p>
              <p
                style={{ textDecorationLine: "line-through" }}
                className="card-text h4 text-center text_p_font _text_color font-weight-bold"
              >
                {getCurrencySymbol(item[1]?.currency)}{" "}
                {item[1]?.product_amount}
              </p>
              <p className="text-center text_plan_days_ _text_color my-2 pb-5">
                <span>{item[1]?.no_of_session} days</span>
              </p>
              <div
                style={{ bottom: 0, background: "#ffff" }}
                className="text-center position-absolute w-100 m-0 p-0 border rounded mb-2"
              >
                <button
                  onClick={() => this.gotoPay(item[1])}
                  style={{ color: "#215352", fontWeight: "bold" }}
                  className="btn btn-block _text_color"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
          <div className="row col-md-10 mx-auto mt-n4 p-4 d-flex flex-row justify-content-center">
            <div
              style={{ background: "#c8e6e3" }}
              className="col-md-4 m-3 shadow rounded p-0 position-relative"
            >
              <h5 className=" text-center text_font _text_color pt-3 header_font_">
                🌿 {item[2]?.plan}
              </h5>
              <p className="card-text h2 text-center text_p_font _text_color font-weight-bold">
                {getCurrencySymbol(item[2]?.currency)} {item[2]?.amount}
              </p>
              <p
                style={{ textDecorationLine: "line-through" }}
                className="card-text h4 text-center text_p_font _text_color font-weight-bold"
              >
                {getCurrencySymbol(item[2]?.currency)}{" "}
                {item[2]?.product_amount}
              </p>
              <p className="text-center text_plan_days_ _text_color my-2 pb-5">
                <span>{item[2]?.no_of_session} days</span>
              </p>
              <div
                style={{ bottom: 0, background: "#ffff" }}
                className="text-center position-absolute w-100 m-0 p-0 border rounded mb-2"
              >
                <button
                  onClick={() => this.gotoPay(item[2])}
                  style={{ color: "#215352", fontWeight: "bold" }}
                  className="btn btn-block _text_color"
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div
              style={{ background: "#e3d1d2" }}
              className="col-md-4 m-3 shadow rounded p-0  position-relative"
            >
              <h5 className=" text-center text_font _text_color pt-3 header_font_">
                🌟 {item[3]?.plan}
              </h5>
              <p className="card-text h2 text-center text_p_font _text_color font-weight-bold">
                {getCurrencySymbol(item[3]?.currency)} {item[3]?.amount}{" "}
              </p>
              <p
                style={{ textDecorationLine: "line-through" }}
                className="card-text h4 text-center text_p_font _text_color font-weight-bold"
              >
                {getCurrencySymbol(item[3]?.currency)}{" "}
                {item[3]?.product_amount}
              </p>
              <p className="text-center text_plan_days_ _text_color my-2 pb-5">
                <span>{item[3]?.no_of_session} days</span>
              </p>
              <div
                style={{ bottom: 0, background: "#ffff" }}
                className="text-center position-absolute w-100 m-0 p-0 border rounded mb-2"
              >
                <button
                  onClick={() => this.gotoPay(item[3])}
                  style={{ color: "#215352", fontWeight: "bold" }}
                  className="btn btn-block _text_color"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{ color: "#fff", fontSize: "12px" }}
          className="px-md-4 px-1 mt-3 text_p_"
        >
          Note: Our expert coaches are available 24/7 to support you, but
          please note that you may not always receive coaching from the same
          coach. Regardless of who you work with, you'll receive compassionate
          and expert guidance to help you navigate life's challenges.
        </p>
      </div>
    </div>
    );
  }
}

export default Chatpay;
