import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { ArrowLeft, Disc, Info, PhoneCall } from "react-feather";
import { BsBarChart, BsChevronLeft, BsHouse, BsWhatsapp } from "react-icons/bs";
import { CloseButton } from 'react-bootstrap';
import {Modal,ModalFooter} from 'reactstrap';
import { withRouter } from "react-router-dom";
import { Flash } from "react-ionicons";
import { saveAs } from "file-saver";
import { CDN_URL } from "../processENV";

const TrackViewEnglish = (props) => {
  const { navigation } = props;
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [selfc, setSelfC] = useState(false);
  const [resultdata, setResultData] = useState({});

  const openSheet = () => {
    setSheetOpen(true);
  };

  const closeSheet = () => {
    setSheetOpen(false);
  };
  useEffect(()=>{
    const data=props.location.state.data
    setResultData(data)
  },[props])

  const cleanHTML = (html) => {
    if (!html) {
      return ''; 
    }
      const modifiedHtml = html.replace('<ol>', '<ol class="custom-list">');
      const cleanedHtml = modifiedHtml.replace(/<p><br><\/p>/g, '');
      return cleanedHtml;
  };
const downloadfile=(filename,fileurl)=>{
  saveAs(fileurl,filename)
}
  return (
    <div className="topcontainer_ col-md-12 mx-auto  m-0 p-0" >
      <Modal isOpen={selfc} className='position-relative' centered size='md'>
          <CloseButton onClick={()=>setSelfC(false)} style={{right:15,top:15}} className='position-absolute' />
            <div className='p-5 '>
              <h5 className='text_p_ mt-md-4 font-weight-bold'>Unfortunately, it seems that the self-care courses are not currently available on our web app. We apologize for the inconvenience.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>To access these courses, please download our mobile app from the App Store or Google Play.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>Thank you for your patience!</h5>
            </div>
            <ModalFooter>
            <div className="mx-auto">
                <div className="text-center">
                <img
                  src={`${CDN_URL}/Others/qr-code.png`}
                  className="img-fluid text-center mx-auto"
                />
                </div>
              <div className='d-flex flex-row my-4 mx-auto justify-content-center'>
                <img onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.careme')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/playstore.png`} className='img-fluid m-2'/>
                <img onClick={()=>window.open('https://apps.apple.com/GH/app/id1553777469?l=en')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/appstore.png`} className='img-fluid m-2' />
              </div>
              </div>
            </ModalFooter>
        </Modal>
        < Modal isOpen={isSheetOpen} className='position-relative' centered size='md'>
            <div className="modal-content m-0">
              <div className="modal-header">
                <h5 className="modal-title header_font_ _text_color">
                 Understand the science behind your score
                 </h5>
                <button type="button" className="close" onClick={closeSheet}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  style={{ opacity: 0.7 }}
                  className="text_p_ font-weight-light px-2"
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(resultdata?.score_header),
                  }}
                />
                <div
                  className="d-flex my-3 pt-3 px-2 rounded"
                  style={{ backgroundColor: "rgba(0,0,0,4%)" }}
                >
                  <div className="flex-fill ">
                    <div
                      className="progress"
                      style={{
                        height: "20px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor: "#f5a97a" }}
                      ></div>
                    </div>
                    <p className="text-center py-0 mt-n3 font-weight-light text_p_">
                    Needs Attention
                    </p>
                  </div>
                  <div className="flex-fill mx-2">
                    <div
                      className="progress rounded-0"
                      style={{ height: "20px" }}
                    >
                      <div
                        className="progress-bar rounded-0"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor: "#74e86f" }}
                      ></div>
                    </div>
                    <p className="text-center mt-n3 font-weight-light text_p_">
                    Improving 
                    </p>
                  </div>
                  <div className="flex-fill">
                    <div
                      className="progress"
                      style={{
                        height: "20px",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor: "#698ec9" }}
                      ></div>
                    </div>
                    <p className="text-center mt-n3 font-weight-light text_p_">
                    Well-balanced
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-5">
                <div className="">
                  <div className="flex-fill d-flex">
                    <div
                      className="progress"
                      style={{ height: "18px", width: "10%" }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor: "#f5a97a" }}
                      ></div>
                    </div>
                    <span className="text-dark px-2 header_font_">
                      {resultdata?.score_metrics?.low_range}
                    </span>
                  </div>
                  <p className="mt-n3 text_p_ font-weight-light">
                  <div
                  style={{opacity:.7}}
                    dangerouslySetInnerHTML={{
                      __html:cleanHTML(resultdata?.score_metrics_definitions?.low_range),
                    }}
                  />
                  </p>
                </div>

                <div className="pt-3">
                  <div className="">
                    <div className="flex-fill d-flex">
                      <div
                        className="progress"
                        style={{ height: "18px", width: "10%" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%", backgroundColor: "#74e86f" }}
                        ></div>
                      </div>
                      <span className="text-dark px-2 header_font_">
                        {resultdata?.score_metrics?.moderate_range}
                      </span>
                    </div>
                    <p className="mt-n3 text_p_ font-weight-light">
                    <div
                    style={{opacity:.7}}
                      dangerouslySetInnerHTML={{
                        __html:cleanHTML(resultdata?.score_metrics_definitions?.moderate_range),
                      }}
                    />
                    </p>
                  </div>
                </div>

                <div className="pt-3">
                  <div className="">
                    <div className="flex-fill d-flex">
                      <div
                        className="progress"
                        style={{ height: "18px", width: "10%" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%", backgroundColor: "#698ec9" }}
                        ></div>
                      </div>
                      <span className="text-dark px-2 header_font_">
                        {resultdata?.score_metrics?.high_range}
                      </span>
                    </div>
                    <p className="mt-n3 text_p_ font-weight-light">
                    <div
                    style={{opacity:.7}}
                      dangerouslySetInnerHTML={{
                        __html:cleanHTML(resultdata?.score_metrics_definitions?.high_range),
                      }}
                    />
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "#215352",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  onClick={closeSheet}
                >
                  Got it
                </button>
              </div>
            </div>
        </Modal>
      <div className="col-md-12 mx-auto pb-md-5 p-2">
       
        <div className="d-flex  justify-content-between px-md-5  mb-md-5 mb-2 pt-md-5 mt-0 pt-3">
        <button
          onClick={()=>props.history.goBack()}
          className="btn bg-white borderColor fontColor "
            // style={{ borderColor: "#215352",borderWidth:'1px', color: "#215352" }}
          >
            <BsChevronLeft size={20} /> back to results
          </button>
        <button
          onClick={()=>props.history.push('/home')}
          className="btn bg-white borderColor fontColor "
          // style={{ borderColor: "#215352",borderWidth:'1px', color: "#215352" }}
          >
            <BsHouse /> Go to dashboard
          </button>
        </div>
    
        <div className="d-flex flex-column flex-md-row p-0 col-md-12">
        <div className="col-md-7 mx-auto pb-md-5 px-md-4 p-2">
        <div className="col-md-12 mb-4 d-flex align-items-center text-center mx-auto">
        <img
                src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                className="img-fluid"
                style={{ maxHeight: "100px" }}
              />
        <div className=" mx-auto text-left h3 header_font_  fontColor h2 header_font_  _text_color pt-3" style={{opacity:.8}}>
        An in-depth explanation of your well-being assessment score
        </div>
       {(localStorage._nm&&!localStorage._patientKey)? <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'100px'}} />
        :null}
        </div>
        <div className="d-lg-none d-sm-block my-2 px-2">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download our mobile app now!
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
          <div className="resultContainer bg-white py-md-4 px-md-4 p-2">
            <div className="d-flex justify-content-between px-2 py-1">
              <span className="font-weight-light h5">Latest Score</span>
              <span onClick={openSheet} className="text-primary">
                <Info /> Understand the science
              </span>
            </div>
            <div className="boxcontainer p-3 mt-2">
              <div className="scorediagram d-flex justify-content-center align-items-center py-4">
                <div className="d-flex col-md-12 p-0">
                  <div className="flex-fill position-relative flex-grow-1">
                 {resultdata?.progress_status?.toLowerCase() === 'low'?<><div className="banner-text" style={{left:'42%',top:'-40px'}}>
                                {resultdata?.assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "20px", width: "3px" }}
                              ></div></> :<div className="mt-4"></div>}
                    <div
                      className="progress"
                      style={{
                        height: "20px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                     
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor:resultdata?.progress_status?.toLowerCase() === 'low'?'#f5a97a':"#e3e4e6" }}
                      ></div>
                    </div>
                    <p className="text-center py-0 mt-n3 font-weight-light text_p_">
                    Needs Attention
                    </p>
                  </div>
                  <div className="flex-fill mx-2 position-relative flex-grow-1">
                 {resultdata?.progress_status?.toLowerCase()=='moderate'?<> <div className="banner-text" style={{left:'42%',top:'-40px'}}>
                                {resultdata?.assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "20px", width: "3px" }}
                              ></div></>:<div className="mt-4"></div>}
                    <div
                      className="progress rounded-0"
                      style={{ height: "20px" }}
                    >
                      <div
                        className="progress-bar rounded-0"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor:resultdata?.progress_status?.toLowerCase()=='moderate'? "#74e86f":'#e3e4e6' }}
                      ></div>
                    </div>
                    <p className="text-center mt-n3 font-weight-light text_p_">
                    Improving
                    </p>
                  </div>
                  <div className="flex-fill position-relative flex-grow-1">
                  {resultdata?.progress_status?.toLowerCase()=='excellent'?<><div className="banner-text" style={{left:'42%',top:'-40px'}}>
                                {resultdata?.assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "20px", width: "3px" }}
                              ></div></>:<div className="mt-4"></div>}
                    <div
                      className="progress"
                      style={{
                        height: "20px",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor:resultdata?.progress_status?.toLowerCase()=='excellent'? "#698ec9":'#e3e4e6' }}
                      ></div>
                    </div>
                    <p className="text-center mt-n3 font-weight-light text_p_">
                    Well-balanced
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="scrollcontainer">
              <div>
                <div
                style={{opacity:.7}}
                  dangerouslySetInnerHTML={{
                    __html:cleanHTML(resultdata?.content),
                  }}
                />
                <div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                    Recommended Courses
                  </h5>
                  {resultdata?.course?.map((course, index) => {
                    return (
                      <div style={{opacity:.7}} key={index} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div onClick={()=>setSelfC(true)} style={{cursor:'pointer'}} className="d-flex">
                          <p className="mb-0">{course?.recommended_text}</p>
                          <p className="mb-0 pl-3" style={{textDecorationLine:'underline',color:'#0e6ac7'}}>
                            {course?.title}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                  Recommended Daily Pushup's
                  </h5>
                      <div onClick={()=>setSelfC(true)} style={{opacity:.7,textDecorationLine:'underline',color:'#0e6ac7'}} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div className="d-flex">
                          <p className="mb-0">{resultdata?.pushups_title}</p>
                        </div>
                        </div>
                </div>

                <div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                    Recommended Exercise's
                  </h5>
                  {resultdata?.exercise_data?.map((course, index) => {
                    return (
                      <div style={{opacity:.7}} key={index} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div onClick={()=>downloadfile(course.exe_name,course.document)} style={{cursor:'pointer'}} className="d-flex">
                          <p className="mb-0 pl-3" style={{textDecorationLine:'underline',color:'#0e6ac7'}}>
                            {course?.exe_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <h4 className="mt-3 mt-md-5 header_font_ _text_color text-left">
            Tracks
          </h4>
          <div
            class="mx-auto mt-md-3 mt-2  rounded"
            style={{ backgroundColor: "#dbd5ce" }}
          >
            <div class="rounded-box border rounded p-3">
              <p class="text-center mx-auto py-1 h1 ">📈</p>
              {/* <div class="text_p_">To help you gain insights into your well-being journey, we've introduced a powerful chart that tracks all your well-being logs over time. This chart provides a visual representation of your progress, allowing you to monitor and analyze various aspects of your health and happiness.</div> */}
              <div
                style={{ opacity: 0.8, fontSize: "16px" }}
                class="text_p_  font-weight-bold"
              >
                Your record has less than two records,
                Low is two to unlock the full potential of the chart
                We encourage you to register your well-being at least once
                We encourage Record your well-being on several occasions
                By doing so, various factors will shape your journey
                Influence and value patterns and trends
                can be detected.
              </div>
            </div>
          </div>
        </div>
        <div
                className="col-md-4 border ml-md-5 pt-3 rounded shadow-sm mt-md-1 mt-4"
                style={{
                  backgroundColor: "#f7cb83",
                  height: "750px",
                  overflow: "hidden",
                  top: "30px",
                  position: "sticky",
                }}
              >
                <div
                  style={{ borderWidth: "7px" }}
                  className="header_font_ _text_color h2 py-2 border-white text-center border-bottom"
                >
                  Download Our App!
                </div>
                <p className="text_p_ h5 text-left py-2 _text_color">
                  Thank you for taking the wellbeing assessment! 😊 To enhance
                  your journey to better mental health, download our mobile app
                  now! 📱 Dive deeper into personalized insights and tools
                  tailored for you. Let's prioritize your mental well-being
                  together! 💪
                </p>
                <div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌿 Elevate your self-care:
                      </strong>{" "}
                      Find peace with mindfulness, meditation, and relaxation in
                      our app
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌟 Connect and thrive:
                      </strong>{" "}
                      Join a caring community, share, and gain strength
                      together.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🧘‍♂️ Personalized guidance:
                      </strong>
                      Navigate life confidently with expert online counseling.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌈 Expert insights, anytime:
                      </strong>{" "}
                      Tailored strategies for your well-being from top
                      psychiatrists.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌌 24/7 support:
                      </strong>{" "}
                      Get inspired and guided by coaches, anytime.
                    </span>
                  </div>
                </div>
                <div className="d-sm-none d-lg-block d-none ">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download now
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <img
                    className="mx-1"
                    onClick={() =>
                      window.open("https://careme.onelink.me/01QR/bpmu5cly")
                    }
                    style={{
                      width: "170px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    src={`${CDN_URL}/Websiteicons/playstore.png`}
                  />
                  <img
                    className="mx-1"
                    onClick={() =>
                      window.open("https://careme.onelink.me/01QR/bpmu5cly")
                    }
                    style={{
                      width: "170px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    src={`${CDN_URL}/Websiteicons/appstore.png`}
                  />
                </div>
                <div className="h6 py-2 text-center text_p_">
                  For more details please visit{" "}
                  <a href="https://careme.health" target="_blank">
                    www.careme.health
                  </a>
                </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default withRouter(TrackViewEnglish) ;
