import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactShowMoreText from "react-show-more-text";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  CDN_URL,
  REACT_APP_API_URL,
  RET_URL,
  getCurrencySymbol,
} from "../../processENV";
import Loader from "../Loader";
import SessionPlans from "./Plans";
const publicIp = require("react-public-ip");

class SelectSession extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          sessions: [],
          coupons: null,
          couponError: null,
          couponApplied: false,
          selectedPayment: "",
          productDetails: null,
          product: "",
          orderId: "",
          p_name: "",
          p_email: "",
          p_phone: "",
          p_dial_code: "",
          follow_addon: false,
          confim_modal: false,
          cancel_modal: false,
          d_url: "",
          exc_goal: "",
          edit_coupon: false,
          apply_copon: [0, 0, 0],
          coupon_error: [false, false, false],
          coup_valid: [false, false, false],
          app_prod_grp_key: "",
          addonamount: "",
        };
      }
      componentDidMount() {
        this.getName();
        this.getFollow();
        this.getNewProd();
      }
  getName= async()=>{
      let data = {"patient_n_key":localStorage._patientKey}
      fetch(REACT_APP_API_URL+'/patientedit_app/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
        console.log('chat_det',res)
            if(res.status === "success"){
              this.setState({p_name:res.data.first_name,p_dial_code:res.data.dial_code, p_phone:res.data.phone_number,p_email:res.data.email})
            }else{
              console.log('meeting id',res.data)
            }
        })
  }
  getFollow=()=>{
       let id=localStorage.getItem('_i')
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        
        fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log('selected service',res)
              if(res.status === "success"){
                console.log(res)
                this.setState({
                    productDetails:res?.data[0], 
                    sessions:res?.data[0]?.subscription_plan,
                     product:res?.data[0],
                     d_url:res?.data[0]?.doctor_photo,
                     exc_goal:res.exercise_goal,
                     app_prod_grp_key:res?.data[0]?.app_prod_grp_key
                    })
               localStorage.setItem('_amt',JSON.stringify(res.data[0]))
              }else{
                console.log('selected service',res.data)
              }
          })
  } 
  getNewProd=async()=>{
      let data={
          "ip":await publicIp.v4() || "",
           "patient_n_key": localStorage.getItem('_patientKey')
      }
      fetch(`${REACT_APP_API_URL}/products_groups/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
          console.log('new prod',res.data)
            if(res.status === "success"){
              this.setState({addonamount:res.addon})
            }else{
              console.log('new prod',res.data)
            }
        })
  }
  payInit=async(amount,item,id)=>{
      const data= {
  "patient_n_key":localStorage.getItem('_patientKey'),
  "app_payment_n_key": this.state.productDetails.app_payment_n_key,
  "total_session":item.no_of_session,
  "payment_status":"Payment Initiated",
  "followup_amount":amount,
  'addon':this.state.follow_addon,
  "followup_payment_details":id,
  "followup_product_amount":item.amount,
  "followup_discount":item.discount,
  "followup_discount_amount":item.discount_amount,
  "url":"",
  'followup_doctor':this.state.productDetails.doc_app_id ,
  }
  console.log(data)
  fetch(`${REACT_APP_API_URL}/followup_payment/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log(res)
    })
    .catch((err)=>console.log(err))
  }
  submitPayment = (amount,sessions,item) =>{
        console.log('call')
        this.setState({selectedPayment:amount.toString()})
                //   localStorage.setItem('app_payment_n_key',productDetails.app_payment_n_key)
                  let therapyDetails = {
                    "app_payment_n_key": this.state.productDetails.app_payment_n_key,
                    "journey_id": this.state.productDetails.journey_id,
                    "total_session":sessions,
                    'doc_app_id':this.state.productDetails.doc_app_id,
                    "app_prod_n_key": this.state.productDetails.app_prod_n_key,
                    "product_amount": item.amount,
                    "discount": item.discount,
                    "amount":amount.toString(),
                    "discount_amount": item.discount_amount,
                    "product": this.state.productDetails.title,
                    'app_prod_grp_key':this.state.productDetails.app_prod_grp_key,
                    "follow_adon":this.state.follow_addon,
                    "promo_code":item.coupon,
                }
                localStorage.setItem("therapy_details",JSON.stringify(therapyDetails))
                  this.getPaymentLink(amount,item)
  }
  getPaymentLink = (amount,item) =>{
        let orderId = Math.random().toFixed(5).split('.')[1]
        const{p_email,p_name,p_phone,p_dial_code}=this.state;
        this.setState({orderId:orderId})
        console.log(orderId)
        let data = {
        "orderAmount" : amount.toString(), 
        "customerName" : p_name, 
        "customerPhone" : p_phone, 
        "customerEmail" : p_email, 
        "returnUrl" : RET_URL ,
        "currency":item.currency,
        dial_code:p_dial_code,
        order_note:'FollowUp'
    }
    console.log(data)
        fetch(`${REACT_APP_API_URL}/create_paymentorder/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log(res)
              if(res.status === "success"){
                  console.log('payment link', res)
                  localStorage.setItem('orderId',res.orderId)
                  localStorage.setItem('purchase_type',"APPOINTMENT")
                  this.payInit(amount,item,res.orderId)
                  const cf = new global.Cashfree(res.payment_session_id)
                  cf.redirect()
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
  }

  render() {
    const {
        loading,
        productDetails,
        sessions,
        addonamount,
        couponApplied,
        p_name,
        d_url,
        exc_goal,
        coupon_error,
        coup_valid,
        couponError,
        product,
        coupons,
        follow_addon,
        confim_modal,
        cancel_modal,
      } = this.state;
      let { t } = this.props;
        return (
            <>
            <Modal isOpen={confim_modal} >
                <ModalHeader style={{textAlign:'center'}} >Take care of yourself wherever you go</ModalHeader>
                <ModalBody>
               <img src={`${CDN_URL}/newapp/CAREMEPROMISE%20copy.png`} style={{width:'50px',height:'50px',float:'right'}}/>
               <h5 style={{textAlign:'center',textDecoration:'underline'}}>Chat with your therapist anytime</h5> 
               <p><b>Note: </b>This is not a live conversation; your therapist will reply in 24 hours or less, and we promise to send one response every day.</p>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({confim_modal:false})}>Cancel</Button>
                <Button color="primary" onClick={()=>this.setState({follow_addon:true,confim_modal:false})}>Confirm</Button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={cancel_modal} >
                <ModalHeader style={{textAlign:'center'}} >Take care of yourself wherever you go</ModalHeader>
                <ModalBody>
               <p>We meet you where you're at and help get you where you want to be by adding chat support you will be able to connect with your therapist anytime.</p> 
               <h5 style={{textAlign:'center'}}>Are you sure you want to remove it?</h5>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({follow_addon:false,cancel_modal:false})}>Yes</Button>{' '}
                <Button color="primary" onClick={()=>this.setState({cancel_modal:false})}>No</Button>
                </ModalFooter>
            </Modal>
            <div className="therapyhead p-0 col-md-8 mx-auto" >
                {loading && <Loader/>}
                <h3 className="text-center h4 mt-5 pt-5  text_p_ fontColor font-weight-bold">
            {" "}
            Your care, your pace — tailored just for you.{" "}
          </h3>
          {productDetails?.user_type == "New" ? (
            <>
             <div className="d-flex align-items-start col-md-8 mx-auto mt-4 pt-4">
                <div
                  style={{
                    width: "64px",
                    height: "64px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    flexShrink: 0,
                  }}
                >
                  <img
                    src={productDetails?.doctor_photo}
                    alt="Doctor"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="secondColor ml-3 roundedBorder borderColor p-3 flex-grow-1">
                  <p className=" font-weight-light">
                    Hey <span className="font-weight-bolder">{p_name}</span> — I’m genuinely moved by what you shared
                    today. Your courage shows, and that first step is huge. ❤️
                  </p>
                  <p>
                    After everything we talked about, I’ve put together a plan
                    that’s made just for you — no pressure, no rush, just
                    something that can support you where you are right now—your
                    struggles, your strengths, all of it.
                  </p>
                  <p>
                    🧠 I’m thinking we’ll work together over{" "}
                    <span className="font-weight-bolder">
                      {product.total_session} sessions
                    </span>{" "}
                    — spaced gently, so it never feels too much.
                  </p>
                  <p>
                    And if at any point you feel stuck, tired, or unsure —
                    that's okay. I’ve got you. We’ll move through it, together.
                    🤍
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-3 pl-3 mx-auto">
                <a className="btn roundedBorder newbtn mr-3" href="/exercise">
                  Exercise
                </a>
                <a className="btn roundedBorder newbtn" href="/goals">
                  Goals
                </a>
              </div>
              </>):null}
              <div className="d-flex align-items-start col-md-8 mx-auto mt-4 pt-4">
            <div
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                overflow: "hidden",
                flexShrink: 0,
              }}
            >
              <img
                src={productDetails?.doctor_photo}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="secondColor ml-3 roundedBorder borderColor p-3 flex-grow-1">
              {productDetails?.user_type == "New" ? (
                <p>
                  Whenever you’re ready, here are a few ways we can keep going.
                  Take a look and choose what feels doable — I’ll be with you,
                  every step of the way.
                </p>
              ) : (
                <>
                  <p> It’s been meaningful to walk alongside you so far.</p>
                  <p className="pt-0 mt-0">
                    Wherever you are right now — I’m here. When you're ready,
                    here are a few gentle ways we can continue this work
                    together.
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="pl-5 ml-2">
            <SessionPlans
              plans={sessions}
              onClickCard={(amount, no_of_session, item) =>
                this.submitPayment(parseInt(amount), no_of_session, item)
              }
            />
          </div>
        </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData,
        TherapyJourneyData:state.TherapyJourneyData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(SelectSession));
