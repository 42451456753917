import React from 'react';
import { Container, Card, Button, Image } from 'react-bootstrap';
import { CDN_URL } from '../processENV';
import { ArrowRight } from 'react-feather';

const WelcomeScreen = ({ onPressNext }) => {
  return (
    <Container className="py-5 statusColor" style={{minHeight: '100vh' }}>
      <Card className="p-4 border-0 shadow-none statusColor" >
        <h2 className="fontColor mb-3" style={{ fontFamily: 'Poppins-SemiBold', lineHeight: '40px' }}>
          🌟 Welcome to your Wellness Check-in! 🌟
        </h2>

        <p className="text-dark fs-5 fw-semibold" style={{ fontFamily: 'Poppins-Regular' }}>
          Hey there! 👋 Your well-being matters, and we're here to help you understand it better.
        </p>

        <Image
          src={CDN_URL+"/APP/Wellbeing/ases.png"}
          alt="Assessment Visual"
          fluid
          className="mb-4"
          style={{maxWidth:350, borderRadius: 8 }}
        />

        <p className="text-dark fs-6 fw-semibold" style={{ fontFamily: 'Poppins-Regular' }}>
          This quick wellness assessment covers key areas of your life—
          <strong> Emotional, Financial, Sleep, Professional, Physical, and Social Wellness</strong>—so you can see where you're thriving and where you might need a little extra support.
        </p>

        <div className="bg-light p-3 rounded mt-1 mb-4">
          <h5 className="text-primary mb-2" style={{ fontFamily: 'Poppins-SemiBold' }}>
            🔍 What’s in it for you?
          </h5>
          <ul className="ps-3 mb-0" style={{ fontFamily: 'Poppins-Regular', fontSize: 14 }}>
            <li className="text-primary">✅ A personalized breakdown of your well-being</li>
            <li className="text-primary">✅ Actionable insights to improve each area</li>
            <li className="text-primary">✅ An option to book expert-led sessions if you want to go deeper</li>
          </ul>
        </div>

        <div className='d-flex justify-content-between align-items-center mt-3'>
        <p className="text-dark fs-5 fw-semibold " style={{ fontFamily: 'Poppins-Regular' }}>
          It only takes a few minutes—let’s do this! 🚀
        </p>

        <Button
          variant="primary"
          className="px-4 borderColor d-flex align-items-center py-2 font-weight-bold secondColor fontColor ml-auto fw-bold"
          onClick={onPressNext}
        >
          Start Assessment
          <ArrowRight className='ml-3' />
        </Button>
        </div>
        
      </Card>
    </Container>
  );
};

export default WelcomeScreen;
