import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { Check } from "react-feather";
import { CDN_URL } from "../processENV";

const ClosingScreen = () => {
  const onViewResults = () => {
    window.location.href = "/wellbeing-user-profile";
  };

  const onBookSession = () => {
    window.location.href = "/offerings";
  };

  const highlights = [
    `Your personalized scores for each area 📊`,
    `Actionable tips to improve your well-being 💡`,
    `The option to book a session with a professional 🤝`,
  ];

  return (
    <Container className="col-md-12 pt-5 mt-5 align-items-center statusColor justify-content-center">
      <Row className="justify-content-center col-md-12">
        <Col>
          <Card className="p-4 shadow-none border-0 text-center statusColor">
            <div className="d-flex justify-content-between">
              <Image
                src={`${CDN_URL}/APP/Wellbeing/cong.png`}
                alt="Celebration"
                fluid
                style={{ maxHeight: 250 }}
                className="mb-4"
              />
              <div>
                <h2 className="fw-bold text-dark">🎉 You Did It! 🎉</h2>
                <p className="fs-5 text-muted mb-2">
                  Your wellness snapshot is ready!
                </p>
                <h5 className="fw-semibold text-start mt-3 mb-2 text-dark">
                  Here's what you get:
                </h5>

                <ListGroup variant="flush" className="bg-light p-3 rounded">
                  {highlights.map((item, idx) => (
                    <ListGroup.Item
                      key={idx}
                      className="d-flex align-items-start border-0 bg-transparent px-0"
                    >
                      <Check size={20} className="me-2 mt-1 text-success" />
                      <span className="text-dark" style={{ fontSize: "14px" }}>
                        {item}
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>

            <p className="fs-6 text-dark mt-4 text-start">
              📅 Ready to take your wellness to the next level? Let's make it
              happen.
            </p>

            <Row className="mt-4 col-md-10 mx-auto justify-content-center gx-3">
              <Col md={6}>
                <Button
                  variant="outline-success"
                  className="borderColor roundedBorder fontColor w-75 py-2"
                  onClick={onViewResults}
                >
                  📊 View My Results
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  className="btn newbtn roundedBorder w-75 py-2"
                  onClick={onBookSession}
                >
                  📅 Book a Session
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ClosingScreen;
