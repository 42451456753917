import React, { Component } from "react";
import axios from "axios";
import { CDN_URL, REACT_APP_API_URL, REACT_APP_BU_API_URL } from "../processENV";
import { ChevronLeft, ChevronRight, Circle, PhoneCall } from "react-feather";
import {
  BsCheck2Circle,
  BsCircleFill,
  BsExclamationCircle,
  BsWhatsapp,
} from "react-icons/bs";
import moment from "moment";
import { CloseSharp, Flash } from "react-ionicons";
import Caremeloader from "./Caremeloader";
import { Button, Col, Modal, ModalBody } from "react-bootstrap";
import { WhatsApp } from "@material-ui/icons";
import ConfettiAnimation from "./Confetti";
import FullScreenConfetti from "./Confetti";
import AssessmentPage from '../wellbeing/WellbeingAsses';

const stepsData = [
  { label: "Welcome", range: [0, 1] },
  { label: "Emotional", range: [1, 3] },
  { label: "Financial", range: [3, 5] },
  { label: "Sleep", range: [5, 7] },
  { label: "Professional", range: [7, 9] },
  { label: "Physical", range: [9, 11] },
  { label: "Social", range: [11, 13] },
];
const StepIndicator = ({ step, label, range,isLast }) => {
  const [start, end] = range;
  const isCurrent = step >= start && step < end;
  const isCompleted = step >= end;

  const opacity = isCurrent || isCompleted ? 1 : 0.5;

  const renderIcon = () => {
    if (isCurrent) return <Circle size={14} />;
    if (isCompleted) return <BsCheck2Circle />;
    return <BsCircleFill size={12} />;
  };

  return (
    <>
      <p style={{ opacity }} className="text-white d-flex align-items-center">
        <span className="mr-2">{renderIcon()}</span> {label}
      </p>
      {!isLast && <div
        className="mb-1"
        style={{
          width: "3px",
          marginLeft: "5px",
          height: "12px",
          opacity,
          background: "#fff",
          borderRadius: "10px",
        }}
      ></div>}
    </>
  );
};

const RoundProgressBar = ({
  answeredCount,
  questionCount,
  radius,
  strokeWidth,
  color,
}) => {
  const progress = answeredCount / questionCount;
  const circumference = 2 * Math.PI * radius;
  const progressStroke = progress * circumference;
  const remainingStroke = circumference - progressStroke;
  const progressColor = progress >= 0.5 ? "green" : "orange"; 

  return (
    <div>
      <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
        <circle
          cx={radius + strokeWidth / 2}
          cy={radius + strokeWidth / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth / 4}
          fill="none"
        />
        <path
          stroke={progressColor}
          strokeWidth={strokeWidth / 4}
          strokeDasharray={[progressStroke, remainingStroke]}
          d={`M ${radius + strokeWidth / 2}, ${
            strokeWidth / 2
          } A ${radius}, ${radius} 0 ${progress > 0.5 ? 1 : 0}, 1 ${
            radius + strokeWidth / 2
          }, ${radius * 2 + strokeWidth / 2} A ${radius}, ${radius} 0 ${
            progress > 0.5 ? 1 : 0
          }, 1 ${radius + strokeWidth / 2}, ${strokeWidth / 2}`}
          fill="none"
        />
        <text
          x={radius + strokeWidth / 2}
          y={radius + strokeWidth / 2}
          fontSize={12}
          fill="#000"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {answeredCount} / {questionCount}
        </text>
      </svg>
    </div>
  );
};

class wellbeing extends Component {
  constructor(props) {
    super(props);
    this.myButtonRef = React.createRef();
    this.topRef = React.createRef();
    this.state = {
      profile_: "",
      step: 1,
      disable: false,
      quesObj: [],
      bulkData: {},
      indx: 0,
      userinfo: {},
      emotionalscore_: "",
      socialscore_: "",
      physicalscore_: "",
      personalscore_: "",
      academicscore_: "",
      aspirationscore_: "",
      emotionalscale_: "",
      socialscale_: "",
      physicalscale_: "",
      personalscale_: "",
      academicscale_: "",
      aspirationscale_: "",
      showresultmodal: false,
      showLoader: true,
      showcofetti: false,
      emotional_: [
        { value: "Managing stress", selected: false },
        { value: "Procrastination", selected: false },
        { value: "Anger", selected: false },
        { value: "Breaking negative habits", selected: false },
        { value: "Overcoming past traumas", selected: false },
        { value: "Anxiety", selected: false },
        { value: "Finding a sense of purpose", selected: false },
        { value: "ADHD", selected: false },
        { value: "Boosting your self-confidence", selected: false },
        { value: "None of the above ", selected: false },
      ],
      social_: [
        { value: "Fostering new connections", selected: false },
        { value: "DEI/Belonging", selected: false },
        { value: "Sexual issues", selected: false },
        { value: "Enhance relationships", selected: false },
        { value: "Family balance", selected: false },
        { value: "Social anxiety ", selected: false },
        { value: "Resolve conflicts", selected: false },
        { value: "None of the above", selected: false },
      ],
      set1: [
        {
          question:
            "When I want to feel less negative emotion, I change the way I'm thinking about the situation.",
          answer: "",
        },
        { question: "I keep my emotions to myself.", answer: "" },
        {
          question:
            "When I'm faced with a stressful situation, I make myself think about it in a way that helps me stay calm.",
          answer: "",
        },
        {
          question:
            "When I'm feeling negative emotions, I make sure not to express them.",
          answer: "",
        },
        {
          question:
            "I control my emotions by changing the way I think about the situation I'm in.",
          answer: "",
        },
        { question: "I keep a lid on my emotions.", answer: "" },
        {
          question:
            "When I'm faced with a stressful situation, I try to think of it in a way that makes it seem less threatening.",
          answer: "",
        },
        {
          question: "I control my emotions by not expressing them.",
          answer: "",
        },
        {
          question:
            "When I'm upset, I change the way I think about the situation in order to feel more positive.",
          answer: "",
        },
        {
          question:
            "When I'm really feeling positive emotions, I let myself express them fully.",
          answer: "",
        },
      ],
      indx1: 0,
      ans1: [
        { value: "Strongly disagree", selected: false, scale: 1 },
        { value: "Moderately disagree", selected: false, scale: 2 },
        { value: "Slightly disagree", selected: false, scale: 3 },
        { value: "Neither agree nor disagree", selected: false, scale: 4 },
        { value: "Slightly agree", selected: false, scale: 5 },
        { value: "Moderately agree", selected: false, scale: 6 },
        { value: "Strongly agree", selected: false, scale: 7 },
      ],
      set2: [
        {
          question:
            "How often do you feel close to your family members and/or friends? (FES/SCS)",
          answer: "",
          answerOption: [
            { value: "Never", selected: false, scale: 1 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Often", selected: false, scale: 4 },
            { value: "Almost Always", selected: false, scale: 5 },
            { value: "Always", selected: false, scale: 6 },
          ],
        },
        {
          question:
            "How satisfied are you with the support you receive from your family and friends? (BMSLSS/SSQ)",
          answer: "",
          answerOption: [
            { value: "Very Dissatisfied", selected: false, scale: 1 },
            { value: "Moderately Dissatisfied", selected: false, scale: 2 },
            { value: "Slightly Dissatisfied", selected: false, scale: 3 },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              scale: 4,
            },
            { value: "Slightly Satisfied", selected: false, scale: 5 },
            { value: "Moderately Satisfied", selected: false, scale: 6 },
            { value: "Very Satisfied", selected: false, scale: 7 },
          ],
        },
        {
          question:
            "How often do you feel like you have someone to rely on when you need help? (SPS/SSQ) ",
          answer: "",
          answerOption: [
            { value: "Never", selected: false, scale: 1 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Often", selected: false, scale: 4 },
            { value: "Almost Always", selected: false, scale: 5 },
            { value: "Always", selected: false, scale: 6 },
          ],
        },
        {
          question: "How connected do you feel with people around you? (SCS) ",
          answer: "",
          answerOption: [
            { value: "Not at all connected", selected: false, scale: 1 },
            { value: "Slightly connected", selected: false, scale: 2 },
            { value: "Moderately connected", selected: false, scale: 3 },
            { value: "Very connected", selected: false, scale: 4 },
            { value: "Extremely connected", selected: false, scale: 5 },
          ],
        },
        {
          question:
            "How often do you participate in social activities with your family members and/or friends? (FES)",
          answer: "",
          answerOption: [
            { value: "Never", selected: false, scale: 1 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Often", selected: false, scale: 4 },
            { value: "Almost Always", selected: false, scale: 5 },
            { value: "Always", selected: false, scale: 6 },
          ],
        },
        {
          question:
            "How satisfied are you with the quality of your relationships with family members and friends? (BMSLSS/FES)",
          answer: "",
          answerOption: [
            { value: "Very Dissatisfied", selected: false, scale: 1 },
            { value: "Moderately Dissatisfied", selected: false, scale: 2 },
            { value: "Slightly Dissatisfied", selected: false, scale: 3 },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              scale: 4,
            },
            { value: "Slightly Satisfied", selected: false, scale: 5 },
            { value: "Moderately Satisfied", selected: false, scale: 6 },
            { value: "Very Satisfied", selected: false, scale: 7 },
          ],
        },
        {
          question:
            "How often do you feel that your family members and/or friends understand your feelings and emotions? (SPS)",
          answer: "",
          answerOption: [
            { value: "Never", selected: false, scale: 1 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Often", selected: false, scale: 4 },
            { value: "Almost Always", selected: false, scale: 5 },
            { value: "Always", selected: false, scale: 6 },
          ],
        },
        {
          question:
            "How often do you feel lonely or disconnected from those around you? (SCS) ",
          answer: "",
          answerOption: [
            { value: "Never", selected: false, scale: 1 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Often", selected: false, scale: 4 },
            { value: "Almost Always", selected: false, scale: 5 },
            { value: "Always", selected: false, scale: 6 },
          ],
        },
        {
          question:
            "How many people do you feel you can count on for emotional support in times of need? (SSQ)",
          answer: "",
          answerOption: [
            { value: "None", selected: false, scale: 1 },
            { value: "1-2", selected: false, scale: 2 },
            { value: "3-4", selected: false, scale: 3 },
            { value: "5-6", selected: false, scale: 4 },
            { value: "7-8", selected: false, scale: 5 },
            { value: "9 or more", selected: false, scale: 6 },
          ],
        },
        {
          question:
            "How satisfied are you with the opportunities you have to meet new people and form new relationships? (BMSLSS)",
          answer: "",
          answerOption: [
            { value: "Very Dissatisfied", selected: false, scale: 1 },
            { value: "Moderately Dissatisfied", selected: false, scale: 2 },
            { value: "Slightly Dissatisfied", selected: false, scale: 3 },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              scale: 4,
            },
            { value: "Slightly Satisfied", selected: false, scale: 5 },
            { value: "Moderately Satisfied", selected: false, scale: 6 },
            { value: "Very Satisfied", selected: false, scale: 7 },
          ],
        },
      ],
      indx2: 0,
      personal_: [
        { value: "Stress & Anxiety", selected: false },
        { value: "New career", selected: false },
        { value: "Loneliness", selected: false },
        { value: "Communication skills", selected: false },
        { value: "Future Uncertainties", selected: false },
        { value: "Social Media", selected: false },
        { value: "None of the above", selected: false },
      ],
      set3: [
        {
          question:
            "I can always manage to solve difficult problems if I try hard enough.",
          answer: "",
        },
        {
          question:
            "If someone opposes me, I can find the means and ways to get what I want.",
          answer: "",
        },
        {
          question:
            "It is easy for me to stick to my aims and accomplish my goals.",
          answer: "",
        },
        {
          question:
            "I am confident that I could deal efficiently with unexpected events.",
          answer: "",
        },
        {
          question:
            "Thanks to my resourcefulness, I know how to handle unforeseen situations.",
          answer: "",
        },
        {
          question:
            "I can solve most problems if I invest the necessary effort.",
          answer: "",
        },
        {
          question:
            "I can remain calm when facing difficulties because I can rely on my coping abilities.",
          answer: "",
        },
        {
          question:
            "When I am confronted with a problem, I can usually find several solutions.",
          answer: "",
        },
        {
          question: "If I am in trouble, I can usually think of a solution.",
          answer: "",
        },
        { question: "I can usually handle whatever comes my way.", answer: "" },
      ],
      ans3: [
        { value: "Not at all true", selected: false, scale: 1 },
        { value: "Hardly true", selected: false, scale: 2 },
        { value: "Moderately true", selected: false, scale: 3 },
        { value: "Exactly true", selected: false, scale: 4 },
      ],
      indx3: 0,
      physical_: [
        { value: "Fitness/exercise", selected: false },
        { value: "Healthier diet", selected: false },
        { value: "Healthy work", selected: false },
        { value: "Stress management", selected: false },
        { value: "Sleep enhancement", selected: false },
        { value: "Resilience/coping", selected: false },
        { value: "Chronic conditions", selected: false },
        { value: "Self-care routine", selected: false },
        { value: "Physical/mental balance", selected: false },
        { value: "None of the above", selected: false },
      ],
      set4: [
        { question: "Do you feel tired a lot lately?", answer: "" },
        { question: "Is your appetite good?", answer: "" },
        { question: "Do you have a lot of headaches?", answer: "" },
        {
          question: "Have you gained or lost a lot of weight lately?",
          answer: "",
        },
        { question: "Are you not sleeping well?", answer: "" },
        { question: "Do you feel in good health?", answer: "" },
      ],
      ans4: [
        { value: "Strongly Agree", selected: false, scale: 1 },
        { value: "Agree", selected: false, scale: 3 },
        { value: "Neutral", selected: false, scale: 5 },
        { value: "Disagree", selected: false, scale: 8 },
        { value: "Strongly Disagree", selected: false, scale: 10 },
      ],
      indx4: 0,
      academic_: [
        { value: "Study Skills", selected: false },
        { value: "Academic Stress", selected: false },
        { value: "New Path ", selected: false },
        { value: "Collaboration", selected: false },
        { value: "Positive Environment", selected: false },
        { value: "Critical Thinking", selected: false },
        { value: "Online Learning", selected: false },
        { value: "Balance", selected: false },
        { value: "Mentorship", selected: false },
        { value: "None of the above", selected: false },
      ],
      set5: [
        {
          question: "I know why I’m in college and what I want out of it.",
          answer: "",
        },
        {
          question: "My academic goals and purposes are well defined.",
          answer: "",
        },
        {
          question: "Getting a college degree is very important to me.",
          answer: "",
        },
        { question: "I am enjoying my academic work at college.", answer: "" },
        {
          question:
            "Lately, I have been having doubts regarding the value of a college education.",
          answer: "",
        },
      ],
      ans5: [
        { value: "Strongly Agree", selected: false, scale: 1 },
        { value: "Agree", selected: false, scale: 3 },
        { value: "Neutral", selected: false, scale: 5 },
        { value: "Disagree", selected: false, scale: 8 },
        { value: "Strongly Disagree", selected: false, scale: 10 },
      ],
      indx5: 0,
      aspirations_: [
        { value: "Goal Setting", selected: false },
        { value: "Vision Clarity ", selected: false },
        { value: "Skill Development ", selected: false },
        { value: "Networking", selected: false },
        { value: "Career Advancement", selected: false },
        { value: "Peronal Growth", selected: false },
        { value: "Work Satisfaction", selected: false },
        { value: "Life Purpose", selected: false },
        { value: "Achievement Balance", selected: false },
        { value: "None of the above", selected: false },
      ],
      set6: [
        {
          question:
            "I know how to change specific things that I want to change in my life.",
          answer: "",
        },
        {
          question: "I have a good sense of where I am headed in my life.",
          answer: "",
        },
        {
          question:
            "I know what I need to do to get started toward reaching my goals.",
          answer: "",
        },
        { question: "I take charge of my life.", answer: "" },
        {
          question: "I have a plan for making my life more balanced.",
          answer: "",
        },
      ],
      ans6: [
        { value: "Strongly Agree", selected: false, scale: 10 },
        { value: "Agree", selected: false, scale: 8 },
        { value: "Neutral", selected: false, scale: 5 },
        { value: "Disagree", selected: false, scale: 3 },
        { value: "Strongly Disagree", selected: false, scale: 1 },
      ],
      indx6: 0,
      overall_: [],
    };
  }

  componentDidMount() {
    this.getUser();
    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 4000);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(
        REACT_APP_API_URL + "/nmuser_wellbeing/",
        { nm_id: localStorage._nm },
        { headers }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          this.setState({ overall_: res.data.data, step: 20 });
        } else {
          this.setState({ step: 1 });
        }
      });
  }

  getUser = async () => {
    let data = JSON.stringify({ patient_n_key: localStorage._patientKey });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/patientedit_app/", data, { headers })
      .then((res) => {
        console.log(res);
        console.log("patient_e", res);
        this.setState({ userinfo: res.data.data });
      });
  };
  handleForward = () => {
    const {step} = this.state;
    {
      this.setState({ step: step + 1 }, () => {
        if (
          step + 1 == 2 ||
          step + 1 == 5 ||
          step + 1 == 8 ||
          step + 1 == 11 ||
          step + 1 == 14 ||
          step + 1 == 17
        ) {
          this.setState({ disable: false });
        } else if (step + 1 == 20) {
          this.submitData_();
          this.setState({ showcofetti: true }, () => {
            setTimeout(() => {
              this.setState({ showcofetti: false, showresultmodal: true });
            }, 5000);
          });
        } else this.setState({ disable: true });
      });
    }
    this.topRef.current.scrollIntoView({ behavior: "smooth" });
  };
  handleCheck_ = async (index) => {
    const arr = [...this.state.emotional_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ emotional_: arr, disable: false });
  };
  handleCheck1_ = async (index) => {
    const arr = [...this.state.social_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ social_: arr, disable: false });
  };
  handleCheck2_ = async (index) => {
    const arr = [...this.state.personal_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ personal_: arr, disable: false });
  };
  handleCheck3_ = async (index) => {
    const arr = [...this.state.physical_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ physical_: arr, disable: false });
  };
  handleCheck4_ = async (index) => {
    const arr = [...this.state.academic_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ academic_: arr, disable: false });
  };
  handleCheck5_ = async (index) => {
    const arr = [...this.state.aspirations_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ aspirations_: arr, disable: false });
  };
  handleset1_ = async (index, inx) => {
    const arr = [...this.state.set1];
    arr[inx].answer = this.state.ans1[index].value;
    arr[inx].scale = this.state.ans1[index].scale;
    this.setState({ set1: arr }, () => {
      if (this.state.indx1 + 1 == this.state.set1.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx1: this.state.indx1 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    let cognitiveScore = 0;
    let expressiveScore = 0;
    // Loop through the response array
    for (let i = 0; i < filtervalue.length; i++) {
      const itemScore = filtervalue[i];

      // Check if the item is a cognitive reappraisal item
      if ([1, 3, 5, 7, 9].includes(i + 1)) {
        cognitiveScore += itemScore;
      }

      // Check if the item is an expressive suppression item
      if ([2, 4, 6, 8, 10].includes(i + 1)) {
        expressiveScore += itemScore;
      }
    }
    let score = cognitiveScore - expressiveScore;
    this.setState({ emotionalscale_: score });
    if (score && score < 0) {
      this.setState({ emotionalscore_: "Low" });
    } else if (score && score == 0) {
      this.setState({ emotionalscore_: "Moderate" });
    } else if (score && score > 0) {
      this.setState({ emotionalscore_: "Excellent" });
    }
  };
  handleset2_ = async (index, inx) => {
    const arr = [...this.state.set2];
    arr[inx].answer = arr[inx].answerOption[index].value;
    arr[inx].scale = arr[inx].answerOption[index].scale;
    this.setState({ set2: arr }, () => {
      if (this.state.indx2 + 1 == this.state.set2.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx2: this.state.indx2 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ socialscale_: score });
    if (score && score <= 30) {
      this.setState({ socialscore_: "Low" });
    } else if (score && score <= 50 && score >= 31) {
      this.setState({ socialscore_: "Moderate" });
    } else if (score && score <= 62 && score >= 51) {
      this.setState({ socialscore_: "Excellent" });
    }
  };
  handleset3_ = async (index, inx) => {
    const arr = [...this.state.set3];
    arr[inx].answer = this.state.ans3[index].value;
    arr[inx].scale = this.state.ans3[index].scale;
    this.setState({ set3: arr }, () => {
      if (this.state.indx3 + 1 == this.state.set3.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx3: this.state.indx3 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ personalscale_: score });
    console.log(score);
    if (score && score <= 20) {
      this.setState({ personalscore_: "Low" });
    } else if (score && score <= 34 && score >= 21) {
      this.setState({ personalscore_: "Moderate" });
    } else if (score && score <= 40 && score >= 35) {
      this.setState({ personalscore_: "Excellent" });
    }
  };
  handleset4_ = async (index, inx) => {
    const arr = [...this.state.set4];
    arr[inx].answer = this.state.ans4[index].value;
    arr[inx].scale = this.state.ans4[index].scale;
    this.setState({ set4: arr }, () => {
      if (this.state.indx4 + 1 == this.state.set4.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx4: this.state.indx4 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ physicalscale_: score });
    console.log(score);
    if (score && score <= 14) {
      this.setState({ physicalscore_: "Low" });
    } else if (score && score <= 24 && score >= 15) {
      this.setState({ physicalscore_: "Moderate" });
    } else if (score && score <= 60 && score >= 25) {
      this.setState({ physicalscore_: "Excellent" });
    }
  };
  handleset5_ = async (index, inx) => {
    const arr = [...this.state.set5];
    arr[inx].answer = this.state.ans5[index].value;
    arr[inx].scale = this.state.ans5[index].scale;
    this.setState({ set5: arr }, () => {
      if (this.state.indx5 + 1 == this.state.set5.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx5: this.state.indx5 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ academicscale_: score });
    if (score && score <= 19) {
      this.setState({ academicscore_: "Low" });
    } else if (score && score <= 39 && score >= 20) {
      this.setState({ academicscore_: "Moderate" });
    } else if (score && score <= 50 && score >= 40) {
      this.setState({ academicscore_: "Excellent" });
    }
  };
  handleset6_ = async (index, inx) => {
    const arr = [...this.state.set6];
    arr[inx].answer = this.state.ans6[index].value;
    arr[inx].scale = this.state.ans6[index].scale;
    this.setState({ set6: arr }, () => {
      if (this.state.indx6 + 1 == this.state.set6.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx6: this.state.indx6 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ aspirationscale_: score });
    if (score && score <= 19) {
      this.setState({ aspirationscore_: "Low" });
    } else if (score && score <= 39 && score >= 20) {
      this.setState({ aspirationscore_: "Moderate" });
    } else if (score && score <= 50 && score >= 40) {
      this.setState({ aspirationscore_: "Excellent" });
    }
  };

  submitData_ = async () => {
    const {
      emotional_,
      physical_,
      personal_,
      social_,
      academic_,
      aspirations_,
      socialscore_,
      emotionalscore_,
      physicalscore_,
      personalscore_,
      academicscore_,
      aspirationscore_,
      socialscale_,
      physicalscale_,
      personalscale_,
      academicscale_,
      aspirationscale_,
      emotionalscale_,
    } = this.state;
    const emot = emotional_.filter((i) => i.selected === true);
    const soc = social_.filter((i) => i.selected === true);
    const pers = personal_.filter((i) => i.selected === true);
    const phys = physical_.filter((i) => i.selected === true);
    const acad = academic_.filter((i) => i.selected === true);
    const aspi = aspirations_.filter((i) => i.selected === true);
    let data = JSON.stringify({
      emotional: {
        focus_areas: emot.map((i) => i.value),
        assessment_score: emotionalscale_,
        assesment: this.state.set1,
        total_score: 70,
        progress_status: this.state.emotionalscore_,
      },
      social: {
        focus_areas: soc.map((i) => i.value),
        assessment_score: socialscale_,
        assesment: this.state.set2,
        total_score: 63,
        progress_status: this.state.socialscore_,
      },
      personal: {
        focus_areas: pers.map((i) => i.value),
        assessment_score: personalscale_,
        assesment: this.state.set3,
        total_score: 40,
        progress_status: this.state.personalscore_,
      },
      physical: {
        focus_areas: phys.map((i) => i.value),
        assessment_score: physicalscale_,
        assesment: this.state.set4,
        total_score: 60,
        progress_status: this.state.physicalscore_,
      },
      academic: {
        focus_areas: acad.map((i) => i.value),
        assessment_score: academicscale_,
        assesment: this.state.set5,
        total_score: 50,
        progress_status: this.state.academicscore_,
      },
      aspiration: {
        focus_areas: aspi.map((i) => i.value),
        assessment_score: aspirationscale_,
        assesment: this.state.set6,
        total_score: 50,
        progress_status: this.state.aspirationscore_,
      },
      positive_areas: [
        socialscore_ == "Excellent" && "Social",
        emotionalscore_ == "Excellent" && "Emotional",
        physicalscore_ == "Excellent" && "Physical",
        personalscore_ == "Excellent" && "Personal",
        academicscore_ == "Excellent" && "Academic",
        aspirationscore_ == "Excellent" && "Aspiration",
      ].filter((i) => i),
      focus_areas: [
        (socialscore_ == "Low" || socialscore_ == "Moderate") && "Social",
        (emotionalscore_ == "Low" || emotionalscore_ == "Moderate") &&
          "Emotional",
        (physicalscore_ == "Low" || physicalscore_ == "Moderate") && "Physical",
        (personalscore_ == "Low" || personalscore_ == "Moderate") && "Personal",
        (academicscore_ == "Low" || academicscore_ == "Moderate") && "Academic",
        (aspirationscore_ == "Low" || aspirationscore_ == "Moderate") &&
          "Aspiration",
      ].filter((i) => i),
      patient_n_key: localStorage._patientKey || localStorage._nm,
    });
    console.log(JSON.parse(data));
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/welbeing_post/", data, { headers })
      .then((res) => {
        console.log(res);
        axios
          .post(
            REACT_APP_API_URL + "/wellbeing_tracker/",
            { patient_n_key: localStorage._patientKey || localStorage._nm },
            { headers }
          )
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              this.setState({ overall_: res.data.data });
            }
          });
      });
      
  };
  gotoDash = async () => {
    if (this.state.step == 20) {
      this.submitData_();
    }
    this.setState({ step: 1, disable: false });
    window.location.href = "/home";
  };
  goback_ = async () => {
    const { step } = this.state;
    if (step == 1) {
      this.gotoDash();
    } else this.setState({ step: step - 1, disable: false });
  };

  handleuser = () => {
    if (localStorage._patientKey) {
      window.location.href = "/home";
    } else window.location.href = "https://app.careme.health/";
  };

  capitalize = (string) => {
    let capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  };
  firstelement = () => {
    if (this.state.overall_) {
      const keys = Object.keys(this.state.overall_);
      const firstKey = keys[0];
      const createdOnValue = this.state.overall_[firstKey];
      return createdOnValue;
    }
  };
  isCreatedWithin14Days = () => {
    const today = moment().startOf("day");
    const createdOnDate = moment(this.firstelement()?.created_on);
    const dateDifferenceInDays = today.diff(createdOnDate, "days");
    const daysLeft = 14 - dateDifferenceInDays;
    const isWithin14Days =
      dateDifferenceInDays >= 0 && dateDifferenceInDays <= 14;
    const dateLeft = isWithin14Days
      ? today.clone().add(daysLeft, "days")
      : null;
    return {
      isWithin14Days: isWithin14Days,
      daysLeft: isWithin14Days ? daysLeft : null,
      dateLeft: moment(dateLeft).format("DD-MM-YYYY"),
    };
  };
  handleToggle = () => {
    this.setState({ showresultmodal: false });
    window.location.reload()

  };
  render() {
    const {
      step,
      indx,
      emotional_,
      set1,
      indx1,
      ans1,
      social_,
      set2,
      indx2,
      personal_,
      set3,
      ans3,
      indx3,
      physical_,
      ans4,
      set4,
      indx4,
      academic_,
      ans5,
      set5,
      indx5,
      ans6,
      set6,
      indx6,
      aspirations_,
      disable,
      emotionalscore_,
      socialscore_,
      personalscore_,
      physicalscore_,
      academicscore_,
      aspirationscore_,
      userinfo,
      showLoader,
      showcofetti,
      showresultmodal,
    } = this.state;
    return showLoader ? (
      <Caremeloader />
    ) : showcofetti ? (
      <FullScreenConfetti />
    ) : showresultmodal ? (
      <>
        <Modal
          centered
          size="lg"
          show={showresultmodal}
          onHide={this.handleToggle}
        >
          <Modal.Body>
            <h3 className="font-weight-light py-3 text-center header_font_">
              🎉 Congratulations on completing your assessment! 🎉
            </h3>

            <p className="font-weight-light h5 header_font_">
              At Careme Health, we believe in celebrating every step you take
              towards understanding and nurturing your mental well-being.
              Remember, whatever the outcome, it's the small, consistent changes
              that pave the way to success and resilience. Be proud of yourself
              for taking this step. We're here to support you on your journey.
              ❤️
            </p>
            <p className="font-weight-light py-2 h5 text-center header_font_">
              Stay strong and inspired. Your well-being is worth every effort.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleToggle}>Show Results</Button>
          </Modal.Footer>
        </Modal>
      <FullScreenConfetti />

      </>
    ) : (
      <>
        <div
          className={
            step !== 20
              ? "topcontainer_ col-md-12 mx-auto statusColor d-flex m-0 p-0 pb-5"
              : ""
          }
        >
          {step !== 20 ? (
            <Col
            md={4}
            className="rounded d-none d-lg-block"
            style={{ background: "#3B316F", minHeight: "680px" }}
          >
            <div className="mt-md-5 pt-md-5">
              <h2 className="text-white text-center">CareMe Health</h2>
              <p className="text-white text-center fw-bold">Feel better. Live better.</p>
            </div>
      
            <h4 className="text-white mt-md-5 ps-md-5">Understand Your Wellbeing</h4>
      
            <div className="mt-4 mx-auto justify-content-center col-md-9 px-md-5">
              {stepsData.map((item, idx) => (
                <StepIndicator key={idx} step={step} label={item.label} isLast={stepsData.length-1 === idx} range={item.range} />
              ))}
            </div>
          </Col>
          ) : null}
          <div ref={this.topRef} className={step!==20?"col-md-7 mx-auto overflow-auto":''}>
          <AssessmentPage />
          </div>
        </div>
        {step == 20 ? (
          <>
            <div className="d-flex flex-column flex-md-row col-md-10 mx-md-5 mx-0 p-0 p-md-3">
              <div className="col-md-9 mx-auto p-0 table-responsive">
                <div className="col-md-12 mx-auto mt-md-1 d-flex p-0">
                  <img
                    src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                    className="img-fluid"
                    style={{ maxHeight: "100px" }}
                  />
                  <h4
                    style={{ opacity: 0.9 }}
                    className="header_font_ text-center _text_color align-self-center"
                  >
                    Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance
                    and Growth
                  </h4>
                  {(localStorage._nm&&!localStorage._patientKey)? <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'100px'}} />
                  :null}
                </div>
                <div className="d-lg-none d-sm-block my-2 px-2">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download our mobile app now!
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <table className="table table-bordered pb-2 pt-md-5 mt-md-4 pb-md-5 table-fixed rounded table-responsive-md border">
                  <colgroup>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                  </colgroup>
                  <thead>
                    <tr style={{ backgroundColor: "#ddefe5" }}>
                      <th className="py-4 _text_color text-center header_font_ h4">
                        Category
                      </th>
                      <th className="py-4 _text_color header_font_ h4">
                        Needs Attention
                      </th>
                      <th className="py-4 _text_color text-center header_font_ h4">
                        Improving
                      </th>
                      <th className="py-4 _text_color header_font_ h4">
                        Well-balanced
                      </th>
                      <th className="py-4 _text_color text-center header_font_ h4"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.overall_ &&
                      Object.keys(this.state.overall_).map(
                        (category, index) => (
                          <tr
                            onClick={() =>
                              this.props.history.push("/wellbeing-asses-view", {
                                data: this.state.overall_[category],
                              })
                            }
                            className="py-5"
                            key={index}
                          >
                            <td className="text_p_ h5 text-center _text_color py-4">
                              <p>{this.capitalize(category)}</p>
                            </td>
                            <td>
                              {this.state.overall_[category].progress_status ===
                                "Low" && (
                                <div className="position-relative">
                                  <div className="banner-text">
                                    {
                                      this.state.overall_[category]
                                        .assessment_score
                                    }
                                  </div>
                                  <div
                                    className="bg-dark text-center mx-auto pt-4"
                                    style={{ height: "34px", width: "3px" }}
                                  ></div>
                                  <div className="progress mt-0">
                                    <div
                                      className="progress-bar "
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#f5a97a",
                                      }}
                                      aria-valuenow="33.33"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {this.state.overall_[category].progress_status ===
                                "Moderate" && (
                                <div className="position-relative">
                                  <div className="banner-text">
                                    {
                                      this.state.overall_[category]
                                        .assessment_score
                                    }
                                  </div>
                                  <div
                                    className="bg-dark text-center mx-auto pt-4"
                                    style={{ height: "34px", width: "3px" }}
                                  ></div>
                                  <div className="progress mt-0">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#74e86f",
                                      }}
                                      aria-valuenow="66.66"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="position-relative pr-0">
                              {this.state.overall_[category].progress_status ===
                                "Excellent" && (
                                <div
                                  style={{ width: "80%" }}
                                  className="mt-0 d-flex flex-row"
                                >
                                  <div className="position-relative flex-grow-1">
                                    <div className="banner-text">
                                      {
                                        this.state.overall_[category]
                                          .assessment_score
                                      }
                                    </div>
                                    <div
                                      className="bg-dark text-center mx-auto pt-4"
                                      style={{ height: "34px", width: "3px" }}
                                    ></div>
                                    <div className="progress ">
                                      <div
                                        className="progress-bar "
                                        role="progressbar"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#698ec9",
                                        }}
                                        aria-valuenow="100"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <button
                                style={{
                                  background: "#ddefe5",
                                  color: "#215352",
                                }}
                                className="btn mt-2"
                              >
                                Unlock Recommendations
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>

                <div
                  class="mx-auto mt-md-5 mt-2 rounded"
                  style={{ backgroundColor: "rgba(0, 0, 0, 9%)" }}
                >
                  <div class="rounded-box border p-3 d-flex rounded">
                    <div
                      style={{ opacity: 0.7, fontSize: "16px" }}
                      class="text_p_  font-weight-bold"
                    >
                      <b style={{ textDecoration: "underline" }}>
                        📅 Next Assessment Date:{" "}
                        {this.isCreatedWithin14Days().dateLeft}
                      </b>{" "}
                      This periodic check-in helps us provide you with the best
                      support and guidance tailored to your evolving needs.
                      Remember, taking regular assessments allows for better
                      understanding and intervention. Your journey towards
                      better mental health is a continuous process, and we're
                      here to support you every step of the way.
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 mt-md-5 header_font_ _text_color ">
                  Trends
                </h3>
                <div
                  class="mx-auto mt-md-3 mt-2 mb-md-5 rounded"
                  style={{ backgroundColor: "#dbd5ce" }}
                >
                  <div class="rounded-box border rounded p-3">
                    <p class="text-center mx-auto py-1 h1 ">📈</p>
                    <div
                      style={{ opacity: 0.8, fontSize: "16px" }}
                      class="text_p_  font-weight-bold"
                    >
                      If your record has less than two entries, we encourage you
                      to record your well-being at least twice to unlock the
                      full potential of the chart. By recording your well-being
                      on multiple occasions, you can see how different factors
                      can affect your journey and spot valuable patterns and
                      trends.
                    </div>
                  </div>
                </div>
                <div className="text-center my-2">
                  <button
                    onClick={this.handleuser}
                    className="btn"
                    style={{
                      background: "#215352",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Go to dashboard
                  </button>
                </div>
              </div>
              <div
                className="col-md-4 border ml-md-5 ml-2 pt-3 rounded shadow-sm mt-md-1 mt-4"
                style={{
                  backgroundColor: "#f7cb83",
                  height: "750px",
                  overflow: "hidden",
                  top: "30px",
                  position: "sticky",
                }}
              >
                <div
                  style={{ borderWidth: "7px" }}
                  className="header_font_ _text_color h2 py-2 border-white text-center border-bottom"
                >
                  Download Our App!
                </div>
                <p className="text_p_ h5 text-left py-2 _text_color">
                  Thank you for taking the wellbeing assessment! 😊 To enhance
                  your journey to better mental health, download our mobile app
                  now! 📱 Dive deeper into personalized insights and tools
                  tailored for you. Let's prioritize your mental well-being
                  together! 💪
                </p>
                <div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌿 Elevate your self-care:
                      </strong>{" "}
                      Find peace with mindfulness, meditation, and relaxation in
                      our app
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌟 Connect and thrive:
                      </strong>{" "}
                      Join a caring community, share, and gain strength
                      together.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🧘‍♂️ Personalized guidance:
                      </strong>
                      Navigate life confidently with expert online counseling.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌈 Expert insights, anytime:
                      </strong>{" "}
                      Tailored strategies for your well-being from top
                      psychiatrists.
                    </span>
                  </div>
                  <div className="py-1">
                    <span
                      style={{ fontSize: "15px" }}
                      className="text_p_ _text_color"
                    >
                      <strong className="header_font_ _text_color">
                        🌌 24/7 support:
                      </strong>{" "}
                      Get inspired and guided by coaches, anytime.
                    </span>
                  </div>
                </div>
                <div className="d-sm-none d-lg-block d-none ">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download now
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="mr-2 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>
                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="mt-2 p-2 ml-3"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="d-flex justify-content-between py-2 mt-2">
                  <img
                    className="mx-1"
                    onClick={() =>
                      window.open("https://careme.onelink.me/01QR/bpmu5cly")
                    }
                    style={{
                      width: "170px",
                      height: "46px",
                      cursor: "pointer",
                    }}
                    src={`${CDN_URL}/Websiteicons/playstore.png`}
                  />
                  <img
                    className="mx-1"
                    onClick={() =>
                      window.open("https://careme.onelink.me/01QR/bpmu5cly")
                    }
                    style={{
                      width: "170px",
                      height: "46px",
                      cursor: "pointer",
                    }}
                    src={`${CDN_URL}/Websiteicons/appstore.png`}
                  />
                </div>
                <div className="h6 py-2 text-center text_p_">
                  For more details please visit{" "}
                  <a href="https://careme.health" target="_blank">
                    www.careme.health
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
export default wellbeing;
