import React,{Component} from 'react';
// import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Store from '../../Store';
import { LoginStateAction } from '../../Actions/LoginAction';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Progress
} from 'reactstrap';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import caremelogo from '../../assets/images/caremelogo.png'
import profile from '../../assets/images/profilepic.png'
import { Settings, LogOut, User,Users, Send, FileText, CreditCard, Globe, MessageSquare, Home, Menu, ChevronRight } from 'react-feather';
import { Link } from "react-router-dom";
import { showFeedbackModal } from '../../Actions/PopUp';
import { CDN_URL, REACT_APP_API_URL, REACT_APP_BU_API_URL, SITE_URL } from '../processENV';
// import Slider from "react-slick";
import Topbar from './Home/Topbar';
import blog from '../../assets/images/menu/blog.png'
import Therapy from '../../assets/images/menu/Therapy.png'
import Document from '../../assets/images/menu/Document.png'
import Community from '../../assets/images/menu/Community.png'
import home from '../../assets/images/menu/home.png'
import team from '../../assets/images/menu/team.png'
import user from '../../assets/images/menu/user.png'
import logout from '../../assets/images/menu/logout.png'
import goal from '../../assets/images/goal.png'
import medicalreport from '../../assets/images/medicalreport.png' 
import exc from '../../assets/images/exc.png' 
import Sidebar from "react-sidebar";
import { RiCloseLine } from 'react-icons/ri';


class Navsmall extends Component {
    constructor(props) {
        super(props);
        this.state={
            sidebarOpen: false,
            p_name:'',
            p_phone:'',
            p_img:'',
            p_dial:'',
            _isAdmin:false,
            bu_org_id:''
        }
    }

    handleclose=()=>{
        this.setState({sidebarOpen:false})
    }
    alertPop = () => {
        this.setState({sidebarOpen:false})
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to logout",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#441f4b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                Swal.fire(
                    'Logged Out!',
                    'Successfully',
                    'success'
                )
                setTimeout(function () { 
                    window.location.href='https://app.careme.health/'}, 1000);
            }
        })

    }
    getName= async()=>{
        let data = {"patient_n_key":localStorage._patientKey}
        fetch(REACT_APP_API_URL+'/patientedit_app/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
          console.log('chat_det',res)
              if(res.status === "success"){
                this.setState({p_name:res.data.first_name,p_phone:res.data.phone_number,p_email:res.data.email,p_img:res.data.profile_picture,p_dial:res.data.dial_code})
              }else{
                console.log('meeting id',res.data)
              }
          })
          }
    componentDidMount(){
        this.getName()
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res) {
                    this.setState({ _isAdmin:res.admin,bu_org_id:res.bu_details?.bu_org_id  })
                } else {
                    this.setState({ error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }
    capitalise=(id)=>{
        let uper=id[0].toUpperCase()+id.substr(1) 
        return uper
    }
    render() {
        const{p_img,p_name,p_phone,p_dial,_isAdmin,bu_org_id}=this.state;
        return (
            <div className='login-main customnav d-md-none d-lg-block ' style={{zIndex:1299}}>
            <Sidebar
                sidebar={
                    <div>
                        <div 
                         className="row pl-4 pt-4 d-flex statusColor position-relative">
                            {p_img? <img 
                                src={p_img}
                                className='rounded-circle'
                                style={{background:'#215352',objectFit:'contain'}} width='35px' height='35px'
                            />:
                            <div className='mt-2'>
                               <p className="rounded-circle btn newbtn p-3"
                               >{p_name&&p_name[0].toUpperCase()}</p>
                            </div>
                            }
                            {/* <img src={home} style={{background:'#215352',marginTop:'-30px'}} width='32px' height='32px'  /> */}
                            <div className='row'>
                                <div className='col ml-3'>
                                <p className='mt-3' >{p_name&&this.capitalise(p_name)}</p>
                                <p className='mt-n2' >{p_dial} {p_phone}</p>
                                </div>
                                <div style={{right:20,top:4}} className='position-absolute'>
                                <RiCloseLine color={'#8d918f'} size={30} className='ml-auto'  onClick={() => this.setState({sidebarOpen :false})} />
                                </div>
                            </div>
                            

                        </div>
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-4 pl-4 position-relative' style={{color:'#8d918f'}} to='/home' > 
                       <img  src={`${CDN_URL}/Websiteicons/Mobileicongray/home.png`} style={{fill:'#8d918f'}} width='24px' height='24px' />
                     <span style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Home</span> 
                     <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      {_isAdmin?  <Link onClick={()=>this.handleclose()} className='d-flex row pt-4 pl-4 position-relative' style={{color:'#8d918f'}} to='/admin-panel' > 
                       <img  src={`${CDN_URL}/Wellbeing/admingray.png`} style={{fill:'#8d918f'}} width='20px' height='20px' />
                     <span style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Admin panel</span> 
                     <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> :null}
                      {_isAdmin? <Link onClick={()=>this.handleclose()} className='d-flex row pt-4 pl-4 position-relative' style={{color:'#8d918f'}} to='/dashboard' > 
                       <img  src={`${CDN_URL}/Wellbeing/dashboardgray1.png`} style={{fill:'#8d918f'}} width='20px' height='20px' />
                     <span style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Dashboard</span> 
                     <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> :null}
                      {bu_org_id&&bu_org_id==9? <Link onClick={()=>this.handleclose()} className='d-flex row pt-4 pl-4 position-relative' style={{color:'#8d918f'}} to='/dashboard-nm' > 
                       <img  src={`${CDN_URL}/Wellbeing/dashboardgray1.png`} style={{fill:'#8d918f'}} width='20px' height='20px' />
                     <span style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Wellbeing-Dashboard</span> 
                     <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> :null}
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4  position-relative' style={{color:'#8d918f'}} to='/offerings' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/assessment.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'> Therapy</span>
                      <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link>  
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/workbook' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/prescripton.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Assessments</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/user_documents' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/upload.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Upload Documents</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link>
                       <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/exercise' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/exercise.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Exercise</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/chatdetails' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicon/chatmob.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Chat Details</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/billing' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/bill.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Billing</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                   
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/goals' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/goal.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Goals</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/prescription' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/prescripton.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Prescriptions</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='https://careme.health/blog' > 
                       <img src={`${CDN_URL}/Websiteicons/Mobileicongray/blog.png`} width='24px' style={{fill:'#8d918f'}} height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Blogs</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link>
                      <a
                onClick={() => {this.handleclose()}}
                href={`${SITE_URL}/community`}
                className="d-flex row pt-3 pl-4 position-relative"
                style={{ color: "#8d918f" }}
              >
                <img
                  src={
                   `${CDN_URL}/Websiteicons/Mobileicongray/community.png`
                  }
                  width="24px"
                  style={{ fill: "#8d918f" }}
                  height="24px"
                />
                <span
                  style={{
                    fontFamily: "Hanken Grotesk",
                    color: "#8d918f",
                    fontWeight: "bolder ",
                    fontSize: "16px",
                  }}
                  className="pl-2"
                >
                  Community
                </span>
                <ChevronRight
                  className="position-absolute"
                  style={{ right: 20 }}
                  color="#8d918f"
                />
              </a>

                    
                      <Link onClick={()=>this.handleclose()} className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}} to='/wellbeing-user-profile' > 
                       <img style={{fill:'#8d918f'}} src={`${CDN_URL}/Websiteicons%2FMobileicongray%2Faccountdetails.png`} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Account Details</span>
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link>  
                      <Link  className='d-flex row pt-3 pl-4 position-relative' style={{color:'#8d918f'}}  onClick={() => this.alertPop()} > 
                       <img src={`${CDN_URL}/Websiteicons/Mobileicongray/logout.png`} style={{fill:'#8d918f'}} width='24px' height='24px' />
                       <span  style={{fontFamily:'Hanken Grotesk',color:'#8d918f',fontWeight:'bolder ',fontSize:'16px'}} className='pl-2'>Logout</span> 
                       <ChevronRight className='position-absolute' style={{right:20}} color='#8d918f' />
                      </Link> 
                    </div>
                }
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                styles={{ sidebar: { 
                    background: "#fff" ,
                    width:'260px',
                    zIndex:1299,
                } }}
            >

              {!this.state.sidebarOpen&& <div style={{zIndex:999}}  className={`col-md-8 mt-0 py-3 bg-white shadow d-sm-block d-md-none`}>
               <Menu onClick={() => this.setState({sidebarOpen :true})} className='' size={30} />
               </div>}
               {/* <button className='bg-none shadow'  onClick={() => this.setState({sidebarOpen :true})} > */}
                {/* </button>  */}
            </Sidebar>
</div>
        );
    }
}



export default Navsmall;