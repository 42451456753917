import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { connect } from 'react-redux';
import ServiceMain from './ServiceSection/ServiceMain';
import VisitCare from './VisitCare';
import SelectedService from './ServiceSection/SelectedService';

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            appointmentBooked : null,
            therapyStatus : null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }
    
    setAppointmentStatus = (status) =>{
        this.setState({
            appointmentBooked:status
        })
    }

    setTherapyStatus = (status) =>{
        this.setState({
            therapyStatus:status
        })
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };


    render() {
        let { isOpen, appointmentBooked, therapyStatus } = this.state;
        return (
            <>
            <div className={"login-main statusColor"}>
                {/* <Row className="mt-4 ">
              
                        <VisitCare appointmentBooked={appointmentBooked} therapyStatus={therapyStatus}/>
                      
                </Row> */}
                <SelectedService  setAppointmentStatus={this.setAppointmentStatus} setTherapyStatus={this.setTherapyStatus} />
                 
                        {/* <ServiceMain /> */}
            </div>
        </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(HomePage);
